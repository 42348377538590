export default [
  "a-b-2",
  "a-b-off",
  "a-b",
  "abacus-off",
  "abacus",
  "abc",
  "access-point-off",
  "access-point",
  "accessible-off",
  "accessible",
  "activity-heartbeat",
  "activity",
  "ad-2",
  "ad-circle-off",
  "ad-circle",
  "ad-off",
  "ad",
  "address-book-off",
  "address-book",
  "adjustments-alt",
  "adjustments-bolt",
  "adjustments-cancel",
  "adjustments-check",
  "adjustments-code",
  "adjustments-cog",
  "adjustments-dollar",
  "adjustments-down",
  "adjustments-exclamation",
  "adjustments-heart",
  "adjustments-horizontal",
  "adjustments-minus",
  "adjustments-off",
  "adjustments-pause",
  "adjustments-pin",
  "adjustments-plus",
  "adjustments-question",
  "adjustments-search",
  "adjustments-share",
  "adjustments-spark",
  "adjustments-star",
  "adjustments-up",
  "adjustments-x",
  "adjustments",
  "aerial-lift",
  "affiliate",
  "ai",
  "air-balloon",
  "air-conditioning-disabled",
  "air-conditioning",
  "air-traffic-control",
  "alarm-average",
  "alarm-minus",
  "alarm-off",
  "alarm-plus",
  "alarm-snooze",
  "alarm",
  "album-off",
  "album",
  "alert-circle-off",
  "alert-circle",
  "alert-hexagon-off",
  "alert-hexagon",
  "alert-octagon",
  "alert-small-off",
  "alert-small",
  "alert-square-rounded-off",
  "alert-square-rounded",
  "alert-square",
  "alert-triangle-off",
  "alert-triangle",
  "alien",
  "align-box-bottom-center",
  "align-box-bottom-left",
  "align-box-bottom-right",
  "align-box-center-bottom",
  "align-box-center-middle",
  "align-box-center-stretch",
  "align-box-center-top",
  "align-box-left-bottom",
  "align-box-left-middle",
  "align-box-left-stretch",
  "align-box-left-top",
  "align-box-right-bottom",
  "align-box-right-middle",
  "align-box-right-stretch",
  "align-box-right-top",
  "align-box-top-center",
  "align-box-top-left",
  "align-box-top-right",
  "align-center",
  "align-justified",
  "align-left-2",
  "align-left",
  "align-right-2",
  "align-right",
  "alpha",
  "alphabet-arabic",
  "alphabet-bangla",
  "alphabet-cyrillic",
  "alphabet-greek",
  "alphabet-hebrew",
  "alphabet-korean",
  "alphabet-latin",
  "alphabet-thai",
  "alt",
  "ambulance",
  "ampersand",
  "analyze-off",
  "analyze",
  "anchor-off",
  "anchor",
  "angle",
  "ankh",
  "antenna-bars-1",
  "antenna-bars-2",
  "antenna-bars-3",
  "antenna-bars-4",
  "antenna-bars-5",
  "antenna-bars-off",
  "antenna-off",
  "antenna",
  "aperture-off",
  "aperture",
  "api-app-off",
  "api-app",
  "api-off",
  "api",
  "app-window",
  "apple",
  "apps-off",
  "apps",
  "archery-arrow",
  "archive-off",
  "archive",
  "armchair-2-off",
  "armchair-2",
  "armchair-off",
  "armchair",
  "arrow-autofit-content",
  "arrow-autofit-down",
  "arrow-autofit-height",
  "arrow-autofit-left",
  "arrow-autofit-right",
  "arrow-autofit-up",
  "arrow-autofit-width",
  "arrow-back-up-double",
  "arrow-back-up",
  "arrow-back",
  "arrow-badge-down",
  "arrow-badge-left",
  "arrow-badge-right",
  "arrow-badge-up",
  "arrow-bar-both",
  "arrow-bar-down",
  "arrow-bar-left",
  "arrow-bar-right",
  "arrow-bar-to-down",
  "arrow-bar-to-left",
  "arrow-bar-to-right",
  "arrow-bar-to-up",
  "arrow-bar-up",
  "arrow-bear-left-2",
  "arrow-bear-left",
  "arrow-bear-right-2",
  "arrow-bear-right",
  "arrow-big-down-line",
  "arrow-big-down-lines",
  "arrow-big-down",
  "arrow-big-left-line",
  "arrow-big-left-lines",
  "arrow-big-left",
  "arrow-big-right-line",
  "arrow-big-right-lines",
  "arrow-big-right",
  "arrow-big-up-line",
  "arrow-big-up-lines",
  "arrow-big-up",
  "arrow-bounce",
  "arrow-capsule",
  "arrow-curve-left",
  "arrow-curve-right",
  "arrow-down-bar",
  "arrow-down-circle",
  "arrow-down-dashed",
  "arrow-down-from-arc",
  "arrow-down-left-circle",
  "arrow-down-left",
  "arrow-down-rhombus",
  "arrow-down-right-circle",
  "arrow-down-right",
  "arrow-down-square",
  "arrow-down-tail",
  "arrow-down-to-arc",
  "arrow-down",
  "arrow-elbow-left",
  "arrow-elbow-right",
  "arrow-fork",
  "arrow-forward-up-double",
  "arrow-forward-up",
  "arrow-forward",
  "arrow-guide",
  "arrow-iteration",
  "arrow-left-bar",
  "arrow-left-circle",
  "arrow-left-dashed",
  "arrow-left-from-arc",
  "arrow-left-rhombus",
  "arrow-left-right",
  "arrow-left-square",
  "arrow-left-tail",
  "arrow-left-to-arc",
  "arrow-left",
  "arrow-loop-left-2",
  "arrow-loop-left",
  "arrow-loop-right-2",
  "arrow-loop-right",
  "arrow-merge-alt-left",
  "arrow-merge-alt-right",
  "arrow-merge-both",
  "arrow-merge-left",
  "arrow-merge-right",
  "arrow-merge",
  "arrow-move-down",
  "arrow-move-left",
  "arrow-move-right",
  "arrow-move-up",
  "arrow-narrow-down-dashed",
  "arrow-narrow-down",
  "arrow-narrow-left-dashed",
  "arrow-narrow-left",
  "arrow-narrow-right-dashed",
  "arrow-narrow-right",
  "arrow-narrow-up-dashed",
  "arrow-narrow-up",
  "arrow-ramp-left-2",
  "arrow-ramp-left-3",
  "arrow-ramp-left",
  "arrow-ramp-right-2",
  "arrow-ramp-right-3",
  "arrow-ramp-right",
  "arrow-right-bar",
  "arrow-right-circle",
  "arrow-right-dashed",
  "arrow-right-from-arc",
  "arrow-right-rhombus",
  "arrow-right-square",
  "arrow-right-tail",
  "arrow-right-to-arc",
  "arrow-right",
  "arrow-rotary-first-left",
  "arrow-rotary-first-right",
  "arrow-rotary-last-left",
  "arrow-rotary-last-right",
  "arrow-rotary-left",
  "arrow-rotary-right",
  "arrow-rotary-straight",
  "arrow-roundabout-left",
  "arrow-roundabout-right",
  "arrow-sharp-turn-left",
  "arrow-sharp-turn-right",
  "arrow-up-bar",
  "arrow-up-circle",
  "arrow-up-dashed",
  "arrow-up-from-arc",
  "arrow-up-left-circle",
  "arrow-up-left",
  "arrow-up-rhombus",
  "arrow-up-right-circle",
  "arrow-up-right",
  "arrow-up-square",
  "arrow-up-tail",
  "arrow-up-to-arc",
  "arrow-up",
  "arrow-wave-left-down",
  "arrow-wave-left-up",
  "arrow-wave-right-down",
  "arrow-wave-right-up",
  "arrow-zig-zag",
  "arrows-cross",
  "arrows-diagonal-2",
  "arrows-diagonal-minimize-2",
  "arrows-diagonal-minimize",
  "arrows-diagonal",
  "arrows-diff",
  "arrows-double-ne-sw",
  "arrows-double-nw-se",
  "arrows-double-se-nw",
  "arrows-double-sw-ne",
  "arrows-down-up",
  "arrows-down",
  "arrows-exchange-2",
  "arrows-exchange",
  "arrows-horizontal",
  "arrows-join-2",
  "arrows-join",
  "arrows-left-down",
  "arrows-left-right",
  "arrows-left",
  "arrows-maximize",
  "arrows-minimize",
  "arrows-move-horizontal",
  "arrows-move-vertical",
  "arrows-move",
  "arrows-random",
  "arrows-right-down",
  "arrows-right-left",
  "arrows-right",
  "arrows-shuffle-2",
  "arrows-shuffle",
  "arrows-sort",
  "arrows-split-2",
  "arrows-split",
  "arrows-transfer-down",
  "arrows-transfer-up-down",
  "arrows-transfer-up",
  "arrows-up-down",
  "arrows-up-left",
  "arrows-up-right",
  "arrows-up",
  "arrows-vertical",
  "artboard-off",
  "artboard",
  "article-off",
  "article",
  "aspect-ratio-off",
  "aspect-ratio",
  "assembly-off",
  "assembly",
  "asset",
  "asterisk-simple",
  "asterisk",
  "at-off",
  "at",
  "atom-2",
  "atom-off",
  "atom",
  "augmented-reality-2",
  "augmented-reality-off",
  "augmented-reality",
  "auth-2fa",
  "automatic-gearbox",
  "automation",
  "avocado",
  "award-off",
  "award",
  "axe",
  "axis-x",
  "axis-y",
  "baby-bottle",
  "baby-carriage",
  "background",
  "backhoe",
  "backpack-off",
  "backpack",
  "backslash",
  "backspace",
  "badge-3d",
  "badge-4k",
  "badge-8k",
  "badge-ad-off",
  "badge-ad",
  "badge-ar",
  "badge-cc",
  "badge-hd",
  "badge-off",
  "badge-sd",
  "badge-tm",
  "badge-vo",
  "badge-vr",
  "badge-wc",
  "badge",
  "badges-off",
  "badges",
  "baguette",
  "ball-american-football-off",
  "ball-american-football",
  "ball-baseball",
  "ball-basketball",
  "ball-bowling",
  "ball-football-off",
  "ball-football",
  "ball-tennis",
  "ball-volleyball",
  "balloon-off",
  "balloon",
  "ballpen-off",
  "ballpen",
  "ban",
  "bandage-off",
  "bandage",
  "barbell-off",
  "barbell",
  "barcode-off",
  "barcode",
  "barrel-off",
  "barrel",
  "barrier-block-off",
  "barrier-block",
  "baseline-density-large",
  "baseline-density-medium",
  "baseline-density-small",
  "baseline",
  "basket-bolt",
  "basket-cancel",
  "basket-check",
  "basket-code",
  "basket-cog",
  "basket-discount",
  "basket-dollar",
  "basket-down",
  "basket-exclamation",
  "basket-heart",
  "basket-minus",
  "basket-off",
  "basket-pause",
  "basket-pin",
  "basket-plus",
  "basket-question",
  "basket-search",
  "basket-share",
  "basket-star",
  "basket-up",
  "basket-x",
  "basket",
  "bat",
  "bath-off",
  "bath",
  "battery-1",
  "battery-2",
  "battery-3",
  "battery-4",
  "battery-automotive",
  "battery-charging-2",
  "battery-charging",
  "battery-eco",
  "battery-exclamation",
  "battery-off",
  "battery-spark",
  "battery-vertical-1",
  "battery-vertical-2",
  "battery-vertical-3",
  "battery-vertical-4",
  "battery-vertical-charging-2",
  "battery-vertical-charging",
  "battery-vertical-eco",
  "battery-vertical-exclamation",
  "battery-vertical-off",
  "battery-vertical",
  "battery",
  "beach-off",
  "beach",
  "bed-flat",
  "bed-off",
  "bed",
  "beer-off",
  "beer",
  "bell-bolt",
  "bell-cancel",
  "bell-check",
  "bell-code",
  "bell-cog",
  "bell-dollar",
  "bell-down",
  "bell-exclamation",
  "bell-heart",
  "bell-minus",
  "bell-off",
  "bell-pause",
  "bell-pin",
  "bell-plus",
  "bell-question",
  "bell-ringing-2",
  "bell-ringing",
  "bell-school",
  "bell-search",
  "bell-share",
  "bell-star",
  "bell-up",
  "bell-x",
  "bell-z",
  "bell",
  "beta",
  "bible",
  "bike-off",
  "bike",
  "binary-off",
  "binary-tree-2",
  "binary-tree",
  "binary",
  "binoculars",
  "biohazard-off",
  "biohazard",
  "blade",
  "bleach-chlorine",
  "bleach-no-chlorine",
  "bleach-off",
  "bleach",
  "blend-mode",
  "blender",
  "blob",
  "blockquote",
  "bluetooth-connected",
  "bluetooth-off",
  "bluetooth-x",
  "bluetooth",
  "blur-off",
  "blur",
  "bmp",
  "body-scan",
  "bold-off",
  "bold",
  "bolt-off",
  "bolt",
  "bomb",
  "bone-off",
  "bone",
  "bong-off",
  "bong",
  "book-2",
  "book-download",
  "book-off",
  "book-upload",
  "book",
  "bookmark-ai",
  "bookmark-edit",
  "bookmark-minus",
  "bookmark-off",
  "bookmark-plus",
  "bookmark-question",
  "bookmark",
  "bookmarks-off",
  "bookmarks",
  "books-off",
  "books",
  "boom",
  "border-all",
  "border-bottom-plus",
  "border-bottom",
  "border-corner-ios",
  "border-corner-pill",
  "border-corner-rounded",
  "border-corner-square",
  "border-corners",
  "border-horizontal",
  "border-inner",
  "border-left-plus",
  "border-left",
  "border-none",
  "border-outer",
  "border-radius",
  "border-right-plus",
  "border-right",
  "border-sides",
  "border-style-2",
  "border-style",
  "border-top-plus",
  "border-top",
  "border-vertical",
  "bottle-off",
  "bottle",
  "bounce-left",
  "bounce-right",
  "bow",
  "bowl-chopsticks",
  "bowl-spoon",
  "bowl",
  "box-align-bottom-left",
  "box-align-bottom-right",
  "box-align-bottom",
  "box-align-left",
  "box-align-right",
  "box-align-top-left",
  "box-align-top-right",
  "box-align-top",
  "box-margin",
  "box-model-2-off",
  "box-model-2",
  "box-model-off",
  "box-model",
  "box-multiple-0",
  "box-multiple-1",
  "box-multiple-2",
  "box-multiple-3",
  "box-multiple-4",
  "box-multiple-5",
  "box-multiple-6",
  "box-multiple-7",
  "box-multiple-8",
  "box-multiple-9",
  "box-multiple",
  "box-off",
  "box-padding",
  "box",
  "braces-off",
  "braces",
  "brackets-angle-off",
  "brackets-angle",
  "brackets-contain-end",
  "brackets-contain-start",
  "brackets-contain",
  "brackets-off",
  "brackets",
  "braille",
  "brain",
  "brand-4chan",
  "brand-abstract",
  "brand-adobe-after-effect",
  "brand-adobe-illustrator",
  "brand-adobe-indesign",
  "brand-adobe-photoshop",
  "brand-adobe-premier",
  "brand-adobe-xd",
  "brand-adobe",
  "brand-adonis-js",
  "brand-airbnb",
  "brand-airtable",
  "brand-algolia",
  "brand-alipay",
  "brand-alpine-js",
  "brand-amazon",
  "brand-amd",
  "brand-amie",
  "brand-amigo",
  "brand-among-us",
  "brand-android",
  "brand-angular",
  "brand-ansible",
  "brand-ao3",
  "brand-appgallery",
  "brand-apple-arcade",
  "brand-apple-news",
  "brand-apple-podcast",
  "brand-apple",
  "brand-appstore",
  "brand-arc",
  "brand-asana",
  "brand-astro",
  "brand-auth0",
  "brand-aws",
  "brand-azure",
  "brand-backbone",
  "brand-badoo",
  "brand-baidu",
  "brand-bandcamp",
  "brand-bandlab",
  "brand-beats",
  "brand-bebo",
  "brand-behance",
  "brand-bilibili",
  "brand-binance",
  "brand-bing",
  "brand-bitbucket",
  "brand-blackberry",
  "brand-blender",
  "brand-blogger",
  "brand-bluesky",
  "brand-booking",
  "brand-bootstrap",
  "brand-bulma",
  "brand-bumble",
  "brand-bunpo",
  "brand-c-sharp",
  "brand-cake",
  "brand-cakephp",
  "brand-campaignmonitor",
  "brand-carbon",
  "brand-cashapp",
  "brand-chrome",
  "brand-cinema-4d",
  "brand-citymapper",
  "brand-cloudflare",
  "brand-codecov",
  "brand-codepen",
  "brand-codesandbox",
  "brand-cohost",
  "brand-coinbase",
  "brand-comedy-central",
  "brand-coreos",
  "brand-couchdb",
  "brand-couchsurfing",
  "brand-cpp",
  "brand-craft",
  "brand-crunchbase",
  "brand-css3",
  "brand-ctemplar",
  "brand-cucumber",
  "brand-cupra",
  "brand-cypress",
  "brand-d3",
  "brand-databricks",
  "brand-days-counter",
  "brand-dcos",
  "brand-debian",
  "brand-deezer",
  "brand-deliveroo",
  "brand-deno",
  "brand-denodo",
  "brand-deviantart",
  "brand-digg",
  "brand-dingtalk",
  "brand-discord",
  "brand-disney",
  "brand-disqus",
  "brand-django",
  "brand-docker",
  "brand-doctrine",
  "brand-dolby-digital",
  "brand-douban",
  "brand-dribbble",
  "brand-drops",
  "brand-drupal",
  "brand-edge",
  "brand-elastic",
  "brand-electronic-arts",
  "brand-ember",
  "brand-envato",
  "brand-etsy",
  "brand-evernote",
  "brand-facebook",
  "brand-feedly",
  "brand-figma",
  "brand-filezilla",
  "brand-finder",
  "brand-firebase",
  "brand-firefox",
  "brand-fiverr",
  "brand-flickr",
  "brand-flightradar24",
  "brand-flipboard",
  "brand-flutter",
  "brand-fortnite",
  "brand-foursquare",
  "brand-framer-motion",
  "brand-framer",
  "brand-funimation",
  "brand-gatsby",
  "brand-git",
  "brand-github-copilot",
  "brand-github",
  "brand-gitlab",
  "brand-gmail",
  "brand-golang",
  "brand-google-analytics",
  "brand-google-big-query",
  "brand-google-drive",
  "brand-google-fit",
  "brand-google-home",
  "brand-google-maps",
  "brand-google-one",
  "brand-google-photos",
  "brand-google-play",
  "brand-google-podcasts",
  "brand-google",
  "brand-grammarly",
  "brand-graphql",
  "brand-gravatar",
  "brand-grindr",
  "brand-guardian",
  "brand-gumroad",
  "brand-hackerrank",
  "brand-hbo",
  "brand-headlessui",
  "brand-hexo",
  "brand-hipchat",
  "brand-html5",
  "brand-inertia",
  "brand-instagram",
  "brand-intercom",
  "brand-itch",
  "brand-javascript",
  "brand-juejin",
  "brand-kako-talk",
  "brand-kbin",
  "brand-kick",
  "brand-kickstarter",
  "brand-kotlin",
  "brand-laravel",
  "brand-lastfm",
  "brand-leetcode",
  "brand-letterboxd",
  "brand-line",
  "brand-linkedin",
  "brand-linktree",
  "brand-linqpad",
  "brand-livewire",
  "brand-loom",
  "brand-mailgun",
  "brand-mantine",
  "brand-mastercard",
  "brand-mastodon",
  "brand-matrix",
  "brand-mcdonalds",
  "brand-medium",
  "brand-meetup",
  "brand-mercedes",
  "brand-messenger",
  "brand-meta",
  "brand-metabrainz",
  "brand-minecraft",
  "brand-miniprogram",
  "brand-mixpanel",
  "brand-monday",
  "brand-mongodb",
  "brand-my-oppo",
  "brand-mysql",
  "brand-national-geographic",
  "brand-nem",
  "brand-netbeans",
  "brand-netease-music",
  "brand-netflix",
  "brand-nexo",
  "brand-nextcloud",
  "brand-nextjs",
  "brand-nodejs",
  "brand-nord-vpn",
  "brand-notion",
  "brand-npm",
  "brand-nuxt",
  "brand-nytimes",
  "brand-oauth",
  "brand-office",
  "brand-ok-ru",
  "brand-onedrive",
  "brand-onlyfans",
  "brand-open-source",
  "brand-openai",
  "brand-openvpn",
  "brand-opera",
  "brand-pagekit",
  "brand-parsinta",
  "brand-patreon",
  "brand-paypal",
  "brand-paypay",
  "brand-peanut",
  "brand-pepsi",
  "brand-php",
  "brand-picsart",
  "brand-pinterest",
  "brand-planetscale",
  "brand-pnpm",
  "brand-pocket",
  "brand-polymer",
  "brand-powershell",
  "brand-printables",
  "brand-prisma",
  "brand-producthunt",
  "brand-pushbullet",
  "brand-pushover",
  "brand-python",
  "brand-qq",
  "brand-radix-ui",
  "brand-react-native",
  "brand-react",
  "brand-reason",
  "brand-reddit",
  "brand-redhat",
  "brand-redux",
  "brand-revolut",
  "brand-rumble",
  "brand-rust",
  "brand-safari",
  "brand-samsungpass",
  "brand-sass",
  "brand-sentry",
  "brand-sharik",
  "brand-shazam",
  "brand-shopee",
  "brand-sketch",
  "brand-skype",
  "brand-slack",
  "brand-snapchat",
  "brand-snapseed",
  "brand-snowflake",
  "brand-socket-io",
  "brand-solidjs",
  "brand-soundcloud",
  "brand-spacehey",
  "brand-speedtest",
  "brand-spotify",
  "brand-stackoverflow",
  "brand-stackshare",
  "brand-steam",
  "brand-stocktwits",
  "brand-storj",
  "brand-storybook",
  "brand-storytel",
  "brand-strava",
  "brand-stripe",
  "brand-sublime-text",
  "brand-sugarizer",
  "brand-supabase",
  "brand-superhuman",
  "brand-supernova",
  "brand-surfshark",
  "brand-svelte",
  "brand-swift",
  "brand-symfony",
  "brand-tabler",
  "brand-tailwind",
  "brand-taobao",
  "brand-teams",
  "brand-ted",
  "brand-telegram",
  "brand-terraform",
  "brand-tether",
  "brand-thingiverse",
  "brand-threads",
  "brand-threejs",
  "brand-tidal",
  "brand-tiktok",
  "brand-tinder",
  "brand-topbuzz",
  "brand-torchain",
  "brand-toyota",
  "brand-trello",
  "brand-tripadvisor",
  "brand-tumblr",
  "brand-twilio",
  "brand-twitch",
  "brand-twitter",
  "brand-typescript",
  "brand-uber",
  "brand-ubuntu",
  "brand-unity",
  "brand-unsplash",
  "brand-upwork",
  "brand-valorant",
  "brand-vercel",
  "brand-vimeo",
  "brand-vinted",
  "brand-visa",
  "brand-visual-studio",
  "brand-vite",
  "brand-vivaldi",
  "brand-vk",
  "brand-vlc",
  "brand-volkswagen",
  "brand-vsco",
  "brand-vscode",
  "brand-vue",
  "brand-walmart",
  "brand-waze",
  "brand-webflow",
  "brand-wechat",
  "brand-weibo",
  "brand-whatsapp",
  "brand-wikipedia",
  "brand-windows",
  "brand-windy",
  "brand-wish",
  "brand-wix",
  "brand-wordpress",
  "brand-x",
  "brand-xamarin",
  "brand-xbox",
  "brand-xdeep",
  "brand-xing",
  "brand-yahoo",
  "brand-yandex",
  "brand-yarn",
  "brand-yatse",
  "brand-ycombinator",
  "brand-youtube-kids",
  "brand-youtube",
  "brand-zalando",
  "brand-zapier",
  "brand-zeit",
  "brand-zhihu",
  "brand-zoom",
  "brand-zulip",
  "brand-zwift",
  "bread-off",
  "bread",
  "briefcase-2",
  "briefcase-off",
  "briefcase",
  "brightness-2",
  "brightness-auto",
  "brightness-down",
  "brightness-half",
  "brightness-off",
  "brightness-up",
  "brightness",
  "broadcast-off",
  "broadcast",
  "browser-check",
  "browser-off",
  "browser-plus",
  "browser-x",
  "browser",
  "brush-off",
  "brush",
  "bubble-minus",
  "bubble-plus",
  "bubble-tea-2",
  "bubble-tea",
  "bubble-text",
  "bubble-x",
  "bubble",
  "bucket-droplet",
  "bucket-off",
  "bucket",
  "bug-off",
  "bug",
  "building-airport",
  "building-arch",
  "building-bank",
  "building-bridge-2",
  "building-bridge",
  "building-broadcast-tower",
  "building-burj-al-arab",
  "building-carousel",
  "building-castle",
  "building-church",
  "building-circus",
  "building-cog",
  "building-community",
  "building-cottage",
  "building-estate",
  "building-factory-2",
  "building-factory",
  "building-fortress",
  "building-hospital",
  "building-lighthouse",
  "building-minus",
  "building-monument",
  "building-mosque",
  "building-off",
  "building-pavilion",
  "building-plus",
  "building-skyscraper",
  "building-stadium",
  "building-store",
  "building-tunnel",
  "building-warehouse",
  "building-wind-turbine",
  "building",
  "buildings",
  "bulb-off",
  "bulb",
  "bulldozer",
  "burger",
  "bus-off",
  "bus-stop",
  "bus",
  "businessplan",
  "butterfly",
  "cactus-off",
  "cactus",
  "cake-off",
  "cake",
  "calculator-off",
  "calculator",
  "calendar-bolt",
  "calendar-cancel",
  "calendar-check",
  "calendar-clock",
  "calendar-code",
  "calendar-cog",
  "calendar-dollar",
  "calendar-dot",
  "calendar-down",
  "calendar-due",
  "calendar-event",
  "calendar-exclamation",
  "calendar-heart",
  "calendar-minus",
  "calendar-month",
  "calendar-off",
  "calendar-pause",
  "calendar-pin",
  "calendar-plus",
  "calendar-question",
  "calendar-repeat",
  "calendar-sad",
  "calendar-search",
  "calendar-share",
  "calendar-smile",
  "calendar-star",
  "calendar-stats",
  "calendar-time",
  "calendar-up",
  "calendar-user",
  "calendar-week",
  "calendar-x",
  "calendar",
  "camera-ai",
  "camera-bitcoin",
  "camera-bolt",
  "camera-cancel",
  "camera-check",
  "camera-code",
  "camera-cog",
  "camera-dollar",
  "camera-down",
  "camera-exclamation",
  "camera-heart",
  "camera-minus",
  "camera-moon",
  "camera-off",
  "camera-pause",
  "camera-pin",
  "camera-plus",
  "camera-question",
  "camera-rotate",
  "camera-search",
  "camera-selfie",
  "camera-share",
  "camera-spark",
  "camera-star",
  "camera-up",
  "camera-x",
  "camera",
  "camper",
  "campfire",
  "cancel",
  "candle",
  "candy-off",
  "candy",
  "cane",
  "cannabis",
  "cap-projecting",
  "cap-rounded",
  "cap-straight",
  "capsule-horizontal",
  "capsule",
  "capture-off",
  "capture",
  "car-4wd",
  "car-crane",
  "car-crash",
  "car-fan-1",
  "car-fan-2",
  "car-fan-3",
  "car-fan-auto",
  "car-fan",
  "car-garage",
  "car-off",
  "car-suv",
  "car-turbine",
  "car",
  "carambola",
  "caravan",
  "cardboards-off",
  "cardboards",
  "cards",
  "caret-down",
  "caret-left-right",
  "caret-left",
  "caret-right",
  "caret-up-down",
  "caret-up",
  "carousel-horizontal",
  "carousel-vertical",
  "carrot-off",
  "carrot",
  "cash-banknote-off",
  "cash-banknote",
  "cash-off",
  "cash-register",
  "cash",
  "cast-off",
  "cast",
  "cat",
  "category-2",
  "category-minus",
  "category-plus",
  "category",
  "ce-off",
  "ce",
  "cell-signal-1",
  "cell-signal-2",
  "cell-signal-3",
  "cell-signal-4",
  "cell-signal-5",
  "cell-signal-off",
  "cell",
  "certificate-2-off",
  "certificate-2",
  "certificate-off",
  "certificate",
  "chair-director",
  "chalkboard-off",
  "chalkboard",
  "charging-pile",
  "chart-arcs-3",
  "chart-arcs",
  "chart-area-line",
  "chart-area",
  "chart-arrows-vertical",
  "chart-arrows",
  "chart-bar-off",
  "chart-bar-popular",
  "chart-bar",
  "chart-bubble",
  "chart-candle",
  "chart-circles",
  "chart-cohort",
  "chart-column",
  "chart-covariate",
  "chart-donut-2",
  "chart-donut-3",
  "chart-donut-4",
  "chart-donut",
  "chart-dots-2",
  "chart-dots-3",
  "chart-dots",
  "chart-funnel",
  "chart-grid-dots",
  "chart-histogram",
  "chart-infographic",
  "chart-line",
  "chart-pie-2",
  "chart-pie-3",
  "chart-pie-4",
  "chart-pie-off",
  "chart-pie",
  "chart-ppf",
  "chart-radar",
  "chart-sankey",
  "chart-scatter-3d",
  "chart-scatter",
  "chart-treemap",
  "check",
  "checkbox",
  "checklist",
  "checks",
  "checkup-list",
  "cheese",
  "chef-hat-off",
  "chef-hat",
  "cherry",
  "chess-bishop",
  "chess-king",
  "chess-knight",
  "chess-queen",
  "chess-rook",
  "chess",
  "chevron-compact-down",
  "chevron-compact-left",
  "chevron-compact-right",
  "chevron-compact-up",
  "chevron-down-left",
  "chevron-down-right",
  "chevron-down",
  "chevron-left-pipe",
  "chevron-left",
  "chevron-right-pipe",
  "chevron-right",
  "chevron-up-left",
  "chevron-up-right",
  "chevron-up",
  "chevrons-down-left",
  "chevrons-down-right",
  "chevrons-down",
  "chevrons-left",
  "chevrons-right",
  "chevrons-up-left",
  "chevrons-up-right",
  "chevrons-up",
  "chisel",
  "christmas-ball",
  "christmas-tree-off",
  "christmas-tree",
  "circle-arrow-down-left",
  "circle-arrow-down-right",
  "circle-arrow-down",
  "circle-arrow-left",
  "circle-arrow-right",
  "circle-arrow-up-left",
  "circle-arrow-up-right",
  "circle-arrow-up",
  "circle-caret-down",
  "circle-caret-left",
  "circle-caret-right",
  "circle-caret-up",
  "circle-check",
  "circle-chevron-down",
  "circle-chevron-left",
  "circle-chevron-right",
  "circle-chevron-up",
  "circle-chevrons-down",
  "circle-chevrons-left",
  "circle-chevrons-right",
  "circle-chevrons-up",
  "circle-dashed-check",
  "circle-dashed-letter-a",
  "circle-dashed-letter-b",
  "circle-dashed-letter-c",
  "circle-dashed-letter-d",
  "circle-dashed-letter-e",
  "circle-dashed-letter-f",
  "circle-dashed-letter-g",
  "circle-dashed-letter-h",
  "circle-dashed-letter-i",
  "circle-dashed-letter-j",
  "circle-dashed-letter-k",
  "circle-dashed-letter-l",
  "circle-dashed-letter-m",
  "circle-dashed-letter-n",
  "circle-dashed-letter-o",
  "circle-dashed-letter-p",
  "circle-dashed-letter-q",
  "circle-dashed-letter-r",
  "circle-dashed-letter-s",
  "circle-dashed-letter-t",
  "circle-dashed-letter-u",
  "circle-dashed-letter-v",
  "circle-dashed-letter-w",
  "circle-dashed-letter-x",
  "circle-dashed-letter-y",
  "circle-dashed-letter-z",
  "circle-dashed-minus",
  "circle-dashed-number-0",
  "circle-dashed-number-1",
  "circle-dashed-number-2",
  "circle-dashed-number-3",
  "circle-dashed-number-4",
  "circle-dashed-number-5",
  "circle-dashed-number-6",
  "circle-dashed-number-7",
  "circle-dashed-number-8",
  "circle-dashed-number-9",
  "circle-dashed-percentage",
  "circle-dashed-plus",
  "circle-dashed-x",
  "circle-dashed",
  "circle-dot",
  "circle-dotted-letter-a",
  "circle-dotted-letter-b",
  "circle-dotted-letter-c",
  "circle-dotted-letter-d",
  "circle-dotted-letter-e",
  "circle-dotted-letter-f",
  "circle-dotted-letter-g",
  "circle-dotted-letter-h",
  "circle-dotted-letter-i",
  "circle-dotted-letter-j",
  "circle-dotted-letter-k",
  "circle-dotted-letter-l",
  "circle-dotted-letter-m",
  "circle-dotted-letter-n",
  "circle-dotted-letter-o",
  "circle-dotted-letter-p",
  "circle-dotted-letter-q",
  "circle-dotted-letter-r",
  "circle-dotted-letter-s",
  "circle-dotted-letter-t",
  "circle-dotted-letter-u",
  "circle-dotted-letter-v",
  "circle-dotted-letter-w",
  "circle-dotted-letter-x",
  "circle-dotted-letter-y",
  "circle-dotted-letter-z",
  "circle-dotted",
  "circle-half-2",
  "circle-half-vertical",
  "circle-half",
  "circle-key",
  "circle-letter-a",
  "circle-letter-b",
  "circle-letter-c",
  "circle-letter-d",
  "circle-letter-e",
  "circle-letter-f",
  "circle-letter-g",
  "circle-letter-h",
  "circle-letter-i",
  "circle-letter-j",
  "circle-letter-k",
  "circle-letter-l",
  "circle-letter-m",
  "circle-letter-n",
  "circle-letter-o",
  "circle-letter-p",
  "circle-letter-q",
  "circle-letter-r",
  "circle-letter-s",
  "circle-letter-t",
  "circle-letter-u",
  "circle-letter-v",
  "circle-letter-w",
  "circle-letter-x",
  "circle-letter-y",
  "circle-letter-z",
  "circle-minus-2",
  "circle-minus",
  "circle-number-0",
  "circle-number-1",
  "circle-number-2",
  "circle-number-3",
  "circle-number-4",
  "circle-number-5",
  "circle-number-6",
  "circle-number-7",
  "circle-number-8",
  "circle-number-9",
  "circle-off",
  "circle-percentage",
  "circle-plus-2",
  "circle-plus",
  "circle-rectangle-off",
  "circle-rectangle",
  "circle-square",
  "circle-triangle",
  "circle-x",
  "circle",
  "circles-relation",
  "circles",
  "circuit-ammeter",
  "circuit-battery",
  "circuit-bulb",
  "circuit-capacitor-polarized",
  "circuit-capacitor",
  "circuit-cell-plus",
  "circuit-cell",
  "circuit-changeover",
  "circuit-diode-zener",
  "circuit-diode",
  "circuit-ground-digital",
  "circuit-ground",
  "circuit-inductor",
  "circuit-motor",
  "circuit-pushbutton",
  "circuit-resistor",
  "circuit-switch-closed",
  "circuit-switch-open",
  "circuit-voltmeter",
  "clear-all",
  "clear-formatting",
  "click",
  "cliff-jumping",
  "clipboard-check",
  "clipboard-copy",
  "clipboard-data",
  "clipboard-heart",
  "clipboard-list",
  "clipboard-off",
  "clipboard-plus",
  "clipboard-smile",
  "clipboard-text",
  "clipboard-typography",
  "clipboard-x",
  "clipboard",
  "clock-12",
  "clock-2",
  "clock-24",
  "clock-bitcoin",
  "clock-bolt",
  "clock-cancel",
  "clock-check",
  "clock-code",
  "clock-cog",
  "clock-dollar",
  "clock-down",
  "clock-edit",
  "clock-exclamation",
  "clock-heart",
  "clock-hour-1",
  "clock-hour-10",
  "clock-hour-11",
  "clock-hour-12",
  "clock-hour-2",
  "clock-hour-3",
  "clock-hour-4",
  "clock-hour-5",
  "clock-hour-6",
  "clock-hour-7",
  "clock-hour-8",
  "clock-hour-9",
  "clock-minus",
  "clock-off",
  "clock-pause",
  "clock-pin",
  "clock-play",
  "clock-plus",
  "clock-question",
  "clock-record",
  "clock-search",
  "clock-share",
  "clock-shield",
  "clock-star",
  "clock-stop",
  "clock-up",
  "clock-x",
  "clock",
  "clothes-rack-off",
  "clothes-rack",
  "cloud-bitcoin",
  "cloud-bolt",
  "cloud-cancel",
  "cloud-check",
  "cloud-code",
  "cloud-cog",
  "cloud-computing",
  "cloud-data-connection",
  "cloud-dollar",
  "cloud-down",
  "cloud-download",
  "cloud-exclamation",
  "cloud-fog",
  "cloud-heart",
  "cloud-lock-open",
  "cloud-lock",
  "cloud-minus",
  "cloud-network",
  "cloud-off",
  "cloud-pause",
  "cloud-pin",
  "cloud-plus",
  "cloud-question",
  "cloud-rain",
  "cloud-search",
  "cloud-share",
  "cloud-snow",
  "cloud-star",
  "cloud-storm",
  "cloud-up",
  "cloud-upload",
  "cloud-x",
  "cloud",
  "clover-2",
  "clover",
  "clubs",
  "code-asterisk",
  "code-circle-2",
  "code-circle",
  "code-dots",
  "code-minus",
  "code-off",
  "code-plus",
  "code",
  "coffee-off",
  "coffee",
  "coffin",
  "coin-bitcoin",
  "coin-euro",
  "coin-monero",
  "coin-off",
  "coin-pound",
  "coin-rupee",
  "coin-taka",
  "coin-yen",
  "coin-yuan",
  "coin",
  "coins",
  "color-filter",
  "color-picker-off",
  "color-picker",
  "color-swatch-off",
  "color-swatch",
  "column-insert-left",
  "column-insert-right",
  "column-remove",
  "columns-1",
  "columns-2",
  "columns-3",
  "columns-off",
  "columns",
  "comet",
  "command-off",
  "command",
  "compass-off",
  "compass",
  "components-off",
  "components",
  "cone-2",
  "cone-off",
  "cone-plus",
  "cone",
  "confetti-off",
  "confetti",
  "confucius",
  "congruent-to",
  "container-off",
  "container",
  "contract",
  "contrast-2-off",
  "contrast-2",
  "contrast-off",
  "contrast",
  "cooker",
  "cookie-man",
  "cookie-off",
  "cookie",
  "copy-check",
  "copy-minus",
  "copy-off",
  "copy-plus",
  "copy-x",
  "copy",
  "copyleft-off",
  "copyleft",
  "copyright-off",
  "copyright",
  "corner-down-left-double",
  "corner-down-left",
  "corner-down-right-double",
  "corner-down-right",
  "corner-left-down-double",
  "corner-left-down",
  "corner-left-up-double",
  "corner-left-up",
  "corner-right-down-double",
  "corner-right-down",
  "corner-right-up-double",
  "corner-right-up",
  "corner-up-left-double",
  "corner-up-left",
  "corner-up-right-double",
  "corner-up-right",
  "cpu-2",
  "cpu-off",
  "cpu",
  "crane-off",
  "crane",
  "creative-commons-by",
  "creative-commons-nc",
  "creative-commons-nd",
  "creative-commons-off",
  "creative-commons-sa",
  "creative-commons-zero",
  "creative-commons",
  "credit-card-off",
  "credit-card-pay",
  "credit-card-refund",
  "credit-card",
  "cricket",
  "crop-1-1",
  "crop-16-9",
  "crop-3-2",
  "crop-5-4",
  "crop-7-5",
  "crop-landscape",
  "crop-portrait",
  "crop",
  "cross-off",
  "cross",
  "crosshair",
  "crown-off",
  "crown",
  "crutches-off",
  "crutches",
  "crystal-ball",
  "csv",
  "cube-3d-sphere-off",
  "cube-3d-sphere",
  "cube-off",
  "cube-plus",
  "cube-send",
  "cube-spark",
  "cube-unfolded",
  "cube",
  "cup-off",
  "cup",
  "curling",
  "curly-loop",
  "currency-afghani",
  "currency-bahraini",
  "currency-baht",
  "currency-bitcoin",
  "currency-cent",
  "currency-dinar",
  "currency-dirham",
  "currency-dogecoin",
  "currency-dollar-australian",
  "currency-dollar-brunei",
  "currency-dollar-canadian",
  "currency-dollar-guyanese",
  "currency-dollar-off",
  "currency-dollar-singapore",
  "currency-dollar-zimbabwean",
  "currency-dollar",
  "currency-dong",
  "currency-dram",
  "currency-ethereum",
  "currency-euro-off",
  "currency-euro",
  "currency-florin",
  "currency-forint",
  "currency-frank",
  "currency-guarani",
  "currency-hryvnia",
  "currency-iranian-rial",
  "currency-kip",
  "currency-krone-czech",
  "currency-krone-danish",
  "currency-krone-swedish",
  "currency-lari",
  "currency-leu",
  "currency-lira",
  "currency-litecoin",
  "currency-lyd",
  "currency-manat",
  "currency-monero",
  "currency-naira",
  "currency-nano",
  "currency-off",
  "currency-paanga",
  "currency-peso",
  "currency-pound-off",
  "currency-pound",
  "currency-quetzal",
  "currency-real",
  "currency-renminbi",
  "currency-ripple",
  "currency-riyal",
  "currency-rubel",
  "currency-rufiyaa",
  "currency-rupee-nepalese",
  "currency-rupee",
  "currency-shekel",
  "currency-solana",
  "currency-som",
  "currency-taka",
  "currency-tenge",
  "currency-tugrik",
  "currency-won",
  "currency-xrp",
  "currency-yen-off",
  "currency-yen",
  "currency-yuan",
  "currency-zloty",
  "currency",
  "current-location-off",
  "current-location",
  "cursor-off",
  "cursor-text",
  "cut",
  "cylinder-off",
  "cylinder-plus",
  "cylinder",
  "dashboard-off",
  "dashboard",
  "database-cog",
  "database-dollar",
  "database-edit",
  "database-exclamation",
  "database-export",
  "database-heart",
  "database-import",
  "database-leak",
  "database-minus",
  "database-off",
  "database-plus",
  "database-search",
  "database-share",
  "database-smile",
  "database-star",
  "database-x",
  "database",
  "decimal",
  "deer",
  "delta",
  "dental-broken",
  "dental-off",
  "dental",
  "deselect",
  "desk",
  "details-off",
  "details",
  "device-airpods-case",
  "device-airpods",
  "device-airtag",
  "device-analytics",
  "device-audio-tape",
  "device-camera-phone",
  "device-cctv-off",
  "device-cctv",
  "device-computer-camera-off",
  "device-computer-camera",
  "device-desktop-analytics",
  "device-desktop-bolt",
  "device-desktop-cancel",
  "device-desktop-check",
  "device-desktop-code",
  "device-desktop-cog",
  "device-desktop-dollar",
  "device-desktop-down",
  "device-desktop-exclamation",
  "device-desktop-heart",
  "device-desktop-minus",
  "device-desktop-off",
  "device-desktop-pause",
  "device-desktop-pin",
  "device-desktop-plus",
  "device-desktop-question",
  "device-desktop-search",
  "device-desktop-share",
  "device-desktop-star",
  "device-desktop-up",
  "device-desktop-x",
  "device-desktop",
  "device-floppy",
  "device-gamepad-2",
  "device-gamepad-3",
  "device-gamepad",
  "device-heart-monitor",
  "device-imac-bolt",
  "device-imac-cancel",
  "device-imac-check",
  "device-imac-code",
  "device-imac-cog",
  "device-imac-dollar",
  "device-imac-down",
  "device-imac-exclamation",
  "device-imac-heart",
  "device-imac-minus",
  "device-imac-off",
  "device-imac-pause",
  "device-imac-pin",
  "device-imac-plus",
  "device-imac-question",
  "device-imac-search",
  "device-imac-share",
  "device-imac-star",
  "device-imac-up",
  "device-imac-x",
  "device-imac",
  "device-ipad-bolt",
  "device-ipad-cancel",
  "device-ipad-check",
  "device-ipad-code",
  "device-ipad-cog",
  "device-ipad-dollar",
  "device-ipad-down",
  "device-ipad-exclamation",
  "device-ipad-heart",
  "device-ipad-horizontal-bolt",
  "device-ipad-horizontal-cancel",
  "device-ipad-horizontal-check",
  "device-ipad-horizontal-code",
  "device-ipad-horizontal-cog",
  "device-ipad-horizontal-dollar",
  "device-ipad-horizontal-down",
  "device-ipad-horizontal-exclamation",
  "device-ipad-horizontal-heart",
  "device-ipad-horizontal-minus",
  "device-ipad-horizontal-off",
  "device-ipad-horizontal-pause",
  "device-ipad-horizontal-pin",
  "device-ipad-horizontal-plus",
  "device-ipad-horizontal-question",
  "device-ipad-horizontal-search",
  "device-ipad-horizontal-share",
  "device-ipad-horizontal-star",
  "device-ipad-horizontal-up",
  "device-ipad-horizontal-x",
  "device-ipad-horizontal",
  "device-ipad-minus",
  "device-ipad-off",
  "device-ipad-pause",
  "device-ipad-pin",
  "device-ipad-plus",
  "device-ipad-question",
  "device-ipad-search",
  "device-ipad-share",
  "device-ipad-star",
  "device-ipad-up",
  "device-ipad-x",
  "device-ipad",
  "device-landline-phone",
  "device-laptop-off",
  "device-laptop",
  "device-mobile-bolt",
  "device-mobile-cancel",
  "device-mobile-charging",
  "device-mobile-check",
  "device-mobile-code",
  "device-mobile-cog",
  "device-mobile-dollar",
  "device-mobile-down",
  "device-mobile-exclamation",
  "device-mobile-heart",
  "device-mobile-message",
  "device-mobile-minus",
  "device-mobile-off",
  "device-mobile-pause",
  "device-mobile-pin",
  "device-mobile-plus",
  "device-mobile-question",
  "device-mobile-rotated",
  "device-mobile-search",
  "device-mobile-share",
  "device-mobile-star",
  "device-mobile-up",
  "device-mobile-vibration",
  "device-mobile-x",
  "device-mobile",
  "device-nintendo-off",
  "device-nintendo",
  "device-projector",
  "device-remote",
  "device-sd-card",
  "device-sim-1",
  "device-sim-2",
  "device-sim-3",
  "device-sim",
  "device-speaker-off",
  "device-speaker",
  "device-tablet-bolt",
  "device-tablet-cancel",
  "device-tablet-check",
  "device-tablet-code",
  "device-tablet-cog",
  "device-tablet-dollar",
  "device-tablet-down",
  "device-tablet-exclamation",
  "device-tablet-heart",
  "device-tablet-minus",
  "device-tablet-off",
  "device-tablet-pause",
  "device-tablet-pin",
  "device-tablet-plus",
  "device-tablet-question",
  "device-tablet-search",
  "device-tablet-share",
  "device-tablet-star",
  "device-tablet-up",
  "device-tablet-x",
  "device-tablet",
  "device-tv-off",
  "device-tv-old",
  "device-tv",
  "device-unknown",
  "device-usb",
  "device-vision-pro",
  "device-watch-bolt",
  "device-watch-cancel",
  "device-watch-check",
  "device-watch-code",
  "device-watch-cog",
  "device-watch-dollar",
  "device-watch-down",
  "device-watch-exclamation",
  "device-watch-heart",
  "device-watch-minus",
  "device-watch-off",
  "device-watch-pause",
  "device-watch-pin",
  "device-watch-plus",
  "device-watch-question",
  "device-watch-search",
  "device-watch-share",
  "device-watch-star",
  "device-watch-stats-2",
  "device-watch-stats",
  "device-watch-up",
  "device-watch-x",
  "device-watch",
  "devices-2",
  "devices-bolt",
  "devices-cancel",
  "devices-check",
  "devices-code",
  "devices-cog",
  "devices-dollar",
  "devices-down",
  "devices-exclamation",
  "devices-heart",
  "devices-minus",
  "devices-off",
  "devices-pause",
  "devices-pc-off",
  "devices-pc",
  "devices-pin",
  "devices-plus",
  "devices-question",
  "devices-search",
  "devices-share",
  "devices-star",
  "devices-up",
  "devices-x",
  "devices",
  "diabolo-off",
  "diabolo-plus",
  "diabolo",
  "dialpad-off",
  "dialpad",
  "diamond-off",
  "diamond",
  "diamonds",
  "diaper",
  "dice-1",
  "dice-2",
  "dice-3",
  "dice-4",
  "dice-5",
  "dice-6",
  "dice",
  "dimensions",
  "direction-arrows",
  "direction-horizontal",
  "direction-sign-off",
  "direction-sign",
  "direction",
  "directions-off",
  "directions",
  "disabled-2",
  "disabled-off",
  "disabled",
  "disc-golf",
  "disc-off",
  "disc",
  "discount-off",
  "discount",
  "divide",
  "dna-2-off",
  "dna-2",
  "dna-off",
  "dna",
  "dog-bowl",
  "dog",
  "door-enter",
  "door-exit",
  "door-off",
  "door",
  "dots-circle-horizontal",
  "dots-diagonal-2",
  "dots-diagonal",
  "dots-vertical",
  "dots",
  "download-off",
  "download",
  "drag-drop-2",
  "drag-drop",
  "drone-off",
  "drone",
  "drop-circle",
  "droplet-bolt",
  "droplet-cancel",
  "droplet-check",
  "droplet-code",
  "droplet-cog",
  "droplet-dollar",
  "droplet-down",
  "droplet-exclamation",
  "droplet-half-2",
  "droplet-half",
  "droplet-heart",
  "droplet-minus",
  "droplet-off",
  "droplet-pause",
  "droplet-pin",
  "droplet-plus",
  "droplet-question",
  "droplet-search",
  "droplet-share",
  "droplet-star",
  "droplet-up",
  "droplet-x",
  "droplet",
  "droplets",
  "dual-screen",
  "dumpling",
  "e-passport",
  "ear-off",
  "ear-scan",
  "ear",
  "ease-in-control-point",
  "ease-in-out-control-points",
  "ease-in-out",
  "ease-in",
  "ease-out-control-point",
  "ease-out",
  "edit-circle-off",
  "edit-circle",
  "edit-off",
  "edit",
  "egg-cracked",
  "egg-fried",
  "egg-off",
  "egg",
  "eggs",
  "elevator-off",
  "elevator",
  "emergency-bed",
  "empathize-off",
  "empathize",
  "emphasis",
  "engine-off",
  "engine",
  "equal-double",
  "equal-not",
  "equal",
  "eraser-off",
  "eraser",
  "error-404-off",
  "error-404",
  "escalator-down",
  "escalator-up",
  "escalator",
  "exchange-off",
  "exchange",
  "exclamation-circle",
  "exclamation-mark-off",
  "exclamation-mark",
  "explicit-off",
  "explicit",
  "exposure-0",
  "exposure-minus-1",
  "exposure-minus-2",
  "exposure-off",
  "exposure-plus-1",
  "exposure-plus-2",
  "exposure",
  "external-link-off",
  "external-link",
  "eye-bitcoin",
  "eye-bolt",
  "eye-cancel",
  "eye-check",
  "eye-closed",
  "eye-code",
  "eye-cog",
  "eye-discount",
  "eye-dollar",
  "eye-dotted",
  "eye-down",
  "eye-edit",
  "eye-exclamation",
  "eye-heart",
  "eye-minus",
  "eye-off",
  "eye-pause",
  "eye-pin",
  "eye-plus",
  "eye-question",
  "eye-search",
  "eye-share",
  "eye-spark",
  "eye-star",
  "eye-table",
  "eye-up",
  "eye-x",
  "eye",
  "eyeglass-2",
  "eyeglass-off",
  "eyeglass",
  "face-id-error",
  "face-id",
  "face-mask-off",
  "face-mask",
  "fall",
  "favicon",
  "feather-off",
  "feather",
  "fence-off",
  "fence",
  "ferry",
  "fidget-spinner",
  "file-3d",
  "file-ai",
  "file-alert",
  "file-analytics",
  "file-arrow-left",
  "file-arrow-right",
  "file-barcode",
  "file-bitcoin",
  "file-broken",
  "file-certificate",
  "file-chart",
  "file-check",
  "file-code-2",
  "file-code",
  "file-cv",
  "file-database",
  "file-delta",
  "file-description",
  "file-diff",
  "file-digit",
  "file-dislike",
  "file-dollar",
  "file-dots",
  "file-download",
  "file-euro",
  "file-excel",
  "file-export",
  "file-function",
  "file-horizontal",
  "file-import",
  "file-infinity",
  "file-info",
  "file-invoice",
  "file-isr",
  "file-lambda",
  "file-like",
  "file-minus",
  "file-music",
  "file-neutral",
  "file-off",
  "file-orientation",
  "file-pencil",
  "file-percent",
  "file-phone",
  "file-plus",
  "file-power",
  "file-report",
  "file-rss",
  "file-sad",
  "file-scissors",
  "file-search",
  "file-settings",
  "file-shredder",
  "file-signal",
  "file-smile",
  "file-spark",
  "file-spreadsheet",
  "file-stack",
  "file-star",
  "file-symlink",
  "file-text-ai",
  "file-text-spark",
  "file-text",
  "file-time",
  "file-type-bmp",
  "file-type-css",
  "file-type-csv",
  "file-type-doc",
  "file-type-docx",
  "file-type-html",
  "file-type-jpg",
  "file-type-js",
  "file-type-jsx",
  "file-type-pdf",
  "file-type-php",
  "file-type-png",
  "file-type-ppt",
  "file-type-rs",
  "file-type-sql",
  "file-type-svg",
  "file-type-ts",
  "file-type-tsx",
  "file-type-txt",
  "file-type-vue",
  "file-type-xls",
  "file-type-xml",
  "file-type-zip",
  "file-typography",
  "file-unknown",
  "file-upload",
  "file-vector",
  "file-word",
  "file-x",
  "file-zip",
  "file",
  "files-off",
  "files",
  "filter-bolt",
  "filter-cancel",
  "filter-check",
  "filter-code",
  "filter-cog",
  "filter-discount",
  "filter-dollar",
  "filter-down",
  "filter-edit",
  "filter-exclamation",
  "filter-heart",
  "filter-minus",
  "filter-off",
  "filter-pause",
  "filter-pin",
  "filter-plus",
  "filter-question",
  "filter-search",
  "filter-share",
  "filter-star",
  "filter-up",
  "filter-x",
  "filter",
  "filters",
  "fingerprint-off",
  "fingerprint-scan",
  "fingerprint",
  "fire-extinguisher",
  "fire-hydrant-off",
  "fire-hydrant",
  "firetruck",
  "first-aid-kit-off",
  "first-aid-kit",
  "fish-bone",
  "fish-christianity",
  "fish-hook-off",
  "fish-hook",
  "fish-off",
  "fish",
  "flag-2-off",
  "flag-2",
  "flag-3",
  "flag-bitcoin",
  "flag-bolt",
  "flag-cancel",
  "flag-check",
  "flag-code",
  "flag-cog",
  "flag-discount",
  "flag-dollar",
  "flag-down",
  "flag-exclamation",
  "flag-heart",
  "flag-minus",
  "flag-off",
  "flag-pause",
  "flag-pin",
  "flag-plus",
  "flag-question",
  "flag-search",
  "flag-share",
  "flag-spark",
  "flag-star",
  "flag-up",
  "flag-x",
  "flag",
  "flame-off",
  "flame",
  "flare",
  "flask-2-off",
  "flask-2",
  "flask-off",
  "flask",
  "flip-flops",
  "flip-horizontal",
  "flip-vertical",
  "float-center",
  "float-left",
  "float-none",
  "float-right",
  "flower-off",
  "flower",
  "focus-2",
  "focus-auto",
  "focus-centered",
  "focus",
  "fold-down",
  "fold-up",
  "fold",
  "folder-bolt",
  "folder-cancel",
  "folder-check",
  "folder-code",
  "folder-cog",
  "folder-dollar",
  "folder-down",
  "folder-exclamation",
  "folder-heart",
  "folder-minus",
  "folder-off",
  "folder-open",
  "folder-pause",
  "folder-pin",
  "folder-plus",
  "folder-question",
  "folder-root",
  "folder-search",
  "folder-share",
  "folder-star",
  "folder-symlink",
  "folder-up",
  "folder-x",
  "folder",
  "folders-off",
  "folders",
  "forbid-2",
  "forbid",
  "forklift",
  "forms",
  "fountain-off",
  "fountain",
  "frame-off",
  "frame",
  "free-rights",
  "freeze-column",
  "freeze-row-column",
  "freeze-row",
  "fridge-off",
  "fridge",
  "friends-off",
  "friends",
  "frustum-off",
  "frustum-plus",
  "frustum",
  "function-off",
  "function",
  "galaxy",
  "garden-cart-off",
  "garden-cart",
  "gas-station-off",
  "gas-station",
  "gauge-off",
  "gauge",
  "gavel",
  "gender-agender",
  "gender-androgyne",
  "gender-bigender",
  "gender-demiboy",
  "gender-demigirl",
  "gender-epicene",
  "gender-female",
  "gender-femme",
  "gender-genderfluid",
  "gender-genderless",
  "gender-genderqueer",
  "gender-hermaphrodite",
  "gender-intergender",
  "gender-male",
  "gender-neutrois",
  "gender-third",
  "gender-transgender",
  "gender-trasvesti",
  "geometry",
  "ghost-2",
  "ghost-3",
  "ghost-off",
  "ghost",
  "gif",
  "gift-card",
  "gift-off",
  "gift",
  "git-branch-deleted",
  "git-branch",
  "git-cherry-pick",
  "git-commit",
  "git-compare",
  "git-fork",
  "git-merge",
  "git-pull-request-closed",
  "git-pull-request-draft",
  "git-pull-request",
  "gizmo",
  "glass-champagne",
  "glass-cocktail",
  "glass-full",
  "glass-gin",
  "glass-off",
  "glass",
  "globe-off",
  "globe",
  "go-game",
  "golf-off",
  "golf",
  "gps",
  "gradienter",
  "grain",
  "graph-off",
  "graph",
  "grave-2",
  "grave",
  "grid-3x3",
  "grid-4x4",
  "grid-dots",
  "grid-goldenratio",
  "grid-pattern",
  "grid-scan",
  "grill-fork",
  "grill-off",
  "grill-spatula",
  "grill",
  "grip-horizontal",
  "grip-vertical",
  "growth",
  "guitar-pick",
  "gymnastics",
  "h-1",
  "h-2",
  "h-3",
  "h-4",
  "h-5",
  "h-6",
  "hammer-off",
  "hammer",
  "hand-click",
  "hand-finger-down",
  "hand-finger-left",
  "hand-finger-off",
  "hand-finger-right",
  "hand-finger",
  "hand-grab",
  "hand-little-finger",
  "hand-love-you",
  "hand-middle-finger",
  "hand-move",
  "hand-off",
  "hand-ring-finger",
  "hand-sanitizer",
  "hand-stop",
  "hand-three-fingers",
  "hand-two-fingers",
  "hanger-2",
  "hanger-off",
  "hanger",
  "hash",
  "haze-moon",
  "haze",
  "hdr",
  "heading-off",
  "heading",
  "headphones-off",
  "headphones",
  "headset-off",
  "headset",
  "health-recognition",
  "heart-bitcoin",
  "heart-bolt",
  "heart-broken",
  "heart-cancel",
  "heart-check",
  "heart-code",
  "heart-cog",
  "heart-discount",
  "heart-dollar",
  "heart-down",
  "heart-exclamation",
  "heart-handshake",
  "heart-minus",
  "heart-off",
  "heart-pause",
  "heart-pin",
  "heart-plus",
  "heart-question",
  "heart-rate-monitor",
  "heart-search",
  "heart-share",
  "heart-spark",
  "heart-star",
  "heart-up",
  "heart-x",
  "heart",
  "heartbeat",
  "hearts-off",
  "hearts",
  "helicopter-landing",
  "helicopter",
  "helmet-off",
  "helmet",
  "help-circle",
  "help-hexagon",
  "help-octagon",
  "help-off",
  "help-small",
  "help-square-rounded",
  "help-square",
  "help-triangle",
  "help",
  "hemisphere-off",
  "hemisphere-plus",
  "hemisphere",
  "hexagon-3d",
  "hexagon-letter-a",
  "hexagon-letter-b",
  "hexagon-letter-c",
  "hexagon-letter-d",
  "hexagon-letter-e",
  "hexagon-letter-f",
  "hexagon-letter-g",
  "hexagon-letter-h",
  "hexagon-letter-i",
  "hexagon-letter-j",
  "hexagon-letter-k",
  "hexagon-letter-l",
  "hexagon-letter-m",
  "hexagon-letter-n",
  "hexagon-letter-o",
  "hexagon-letter-p",
  "hexagon-letter-q",
  "hexagon-letter-r",
  "hexagon-letter-s",
  "hexagon-letter-t",
  "hexagon-letter-u",
  "hexagon-letter-v",
  "hexagon-letter-w",
  "hexagon-letter-x",
  "hexagon-letter-y",
  "hexagon-letter-z",
  "hexagon-minus-2",
  "hexagon-minus",
  "hexagon-number-0",
  "hexagon-number-1",
  "hexagon-number-2",
  "hexagon-number-3",
  "hexagon-number-4",
  "hexagon-number-5",
  "hexagon-number-6",
  "hexagon-number-7",
  "hexagon-number-8",
  "hexagon-number-9",
  "hexagon-off",
  "hexagon-plus-2",
  "hexagon-plus",
  "hexagon",
  "hexagonal-prism-off",
  "hexagonal-prism-plus",
  "hexagonal-prism",
  "hexagonal-pyramid-off",
  "hexagonal-pyramid-plus",
  "hexagonal-pyramid",
  "hexagons-off",
  "hexagons",
  "hierarchy-2",
  "hierarchy-3",
  "hierarchy-off",
  "hierarchy",
  "highlight-off",
  "highlight",
  "history-off",
  "history-toggle",
  "history",
  "home-2",
  "home-bitcoin",
  "home-bolt",
  "home-cancel",
  "home-check",
  "home-cog",
  "home-dollar",
  "home-dot",
  "home-down",
  "home-eco",
  "home-edit",
  "home-exclamation",
  "home-hand",
  "home-heart",
  "home-infinity",
  "home-link",
  "home-minus",
  "home-move",
  "home-off",
  "home-plus",
  "home-question",
  "home-ribbon",
  "home-search",
  "home-share",
  "home-shield",
  "home-signal",
  "home-spark",
  "home-star",
  "home-stats",
  "home-up",
  "home-x",
  "home",
  "horse-toy",
  "horse",
  "horseshoe",
  "hospital-circle",
  "hospital",
  "hotel-service",
  "hourglass-empty",
  "hourglass-high",
  "hourglass-low",
  "hourglass-off",
  "hourglass",
  "hours-12",
  "hours-24",
  "html",
  "http-connect",
  "http-delete",
  "http-get",
  "http-head",
  "http-options",
  "http-patch",
  "http-post",
  "http-put",
  "http-que",
  "http-trace",
  "ice-cream-2",
  "ice-cream-off",
  "ice-cream",
  "ice-skating",
  "icons-off",
  "icons",
  "id-badge-2",
  "id-badge-off",
  "id-badge",
  "id-off",
  "id",
  "ikosaedr",
  "image-in-picture",
  "inbox-off",
  "inbox",
  "indent-decrease",
  "indent-increase",
  "infinity-off",
  "infinity",
  "info-circle",
  "info-hexagon",
  "info-octagon",
  "info-small",
  "info-square-rounded",
  "info-square",
  "info-triangle",
  "inner-shadow-bottom-left",
  "inner-shadow-bottom-right",
  "inner-shadow-bottom",
  "inner-shadow-left",
  "inner-shadow-right",
  "inner-shadow-top-left",
  "inner-shadow-top-right",
  "inner-shadow-top",
  "input-ai",
  "input-check",
  "input-search",
  "input-spark",
  "input-x",
  "invoice",
  "ironing-1",
  "ironing-2",
  "ironing-3",
  "ironing-off",
  "ironing-steam-off",
  "ironing-steam",
  "ironing",
  "irregular-polyhedron-off",
  "irregular-polyhedron-plus",
  "irregular-polyhedron",
  "italic",
  "jacket",
  "jetpack",
  "jewish-star",
  "join-bevel",
  "join-round",
  "join-straight",
  "joker",
  "jpg",
  "json",
  "jump-rope",
  "karate",
  "kayak",
  "kerning",
  "key-off",
  "key",
  "keyboard-hide",
  "keyboard-off",
  "keyboard-show",
  "keyboard",
  "keyframe-align-center",
  "keyframe-align-horizontal",
  "keyframe-align-vertical",
  "keyframe",
  "keyframes",
  "label-important",
  "label-off",
  "label",
  "ladder-off",
  "ladder",
  "ladle",
  "lambda",
  "lamp-2",
  "lamp-off",
  "lamp",
  "lane",
  "language-hiragana",
  "language-katakana",
  "language-off",
  "language",
  "lasso-off",
  "lasso-polygon",
  "lasso",
  "laurel-wreath-1",
  "laurel-wreath-2",
  "laurel-wreath-3",
  "laurel-wreath",
  "layers-difference",
  "layers-intersect-2",
  "layers-intersect",
  "layers-linked",
  "layers-off",
  "layers-selected-bottom",
  "layers-selected",
  "layers-subtract",
  "layers-union",
  "layout-2",
  "layout-align-bottom",
  "layout-align-center",
  "layout-align-left",
  "layout-align-middle",
  "layout-align-right",
  "layout-align-top",
  "layout-board-split",
  "layout-board",
  "layout-bottombar-collapse",
  "layout-bottombar-expand",
  "layout-bottombar-inactive",
  "layout-bottombar",
  "layout-cards",
  "layout-collage",
  "layout-columns",
  "layout-dashboard",
  "layout-distribute-horizontal",
  "layout-distribute-vertical",
  "layout-grid-add",
  "layout-grid-remove",
  "layout-grid",
  "layout-kanban",
  "layout-list",
  "layout-navbar-collapse",
  "layout-navbar-expand",
  "layout-navbar-inactive",
  "layout-navbar",
  "layout-off",
  "layout-rows",
  "layout-sidebar-inactive",
  "layout-sidebar-left-collapse",
  "layout-sidebar-left-expand",
  "layout-sidebar-right-collapse",
  "layout-sidebar-right-expand",
  "layout-sidebar-right-inactive",
  "layout-sidebar-right",
  "layout-sidebar",
  "layout",
  "leaf-2",
  "leaf-off",
  "leaf",
  "lego-off",
  "lego",
  "lemon-2",
  "lemon",
  "letter-a-small",
  "letter-a",
  "letter-b-small",
  "letter-b",
  "letter-c-small",
  "letter-c",
  "letter-case-lower",
  "letter-case-toggle",
  "letter-case-upper",
  "letter-case",
  "letter-d-small",
  "letter-d",
  "letter-e-small",
  "letter-e",
  "letter-f-small",
  "letter-f",
  "letter-g-small",
  "letter-g",
  "letter-h-small",
  "letter-h",
  "letter-i-small",
  "letter-i",
  "letter-j-small",
  "letter-j",
  "letter-k-small",
  "letter-k",
  "letter-l-small",
  "letter-l",
  "letter-m-small",
  "letter-m",
  "letter-n-small",
  "letter-n",
  "letter-o-small",
  "letter-o",
  "letter-p-small",
  "letter-p",
  "letter-q-small",
  "letter-q",
  "letter-r-small",
  "letter-r",
  "letter-s-small",
  "letter-s",
  "letter-spacing",
  "letter-t-small",
  "letter-t",
  "letter-u-small",
  "letter-u",
  "letter-v-small",
  "letter-v",
  "letter-w-small",
  "letter-w",
  "letter-x-small",
  "letter-x",
  "letter-y-small",
  "letter-y",
  "letter-z-small",
  "letter-z",
  "library-minus",
  "library-photo",
  "library-plus",
  "library",
  "license-off",
  "license",
  "lifebuoy-off",
  "lifebuoy",
  "lighter",
  "line-dashed",
  "line-dotted",
  "line-height",
  "line-scan",
  "line",
  "link-minus",
  "link-off",
  "link-plus",
  "link",
  "list-check",
  "list-details",
  "list-letters",
  "list-numbers",
  "list-search",
  "list-tree",
  "list",
  "live-photo-off",
  "live-photo",
  "live-view",
  "load-balancer",
  "loader-2",
  "loader-3",
  "loader-quarter",
  "loader",
  "location-bolt",
  "location-broken",
  "location-cancel",
  "location-check",
  "location-code",
  "location-cog",
  "location-discount",
  "location-dollar",
  "location-down",
  "location-exclamation",
  "location-heart",
  "location-minus",
  "location-off",
  "location-pause",
  "location-pin",
  "location-plus",
  "location-question",
  "location-search",
  "location-share",
  "location-star",
  "location-up",
  "location-x",
  "location",
  "lock-access-off",
  "lock-access",
  "lock-bitcoin",
  "lock-bolt",
  "lock-cancel",
  "lock-check",
  "lock-code",
  "lock-cog",
  "lock-dollar",
  "lock-down",
  "lock-exclamation",
  "lock-heart",
  "lock-minus",
  "lock-off",
  "lock-open-2",
  "lock-open-off",
  "lock-open",
  "lock-password",
  "lock-pause",
  "lock-pin",
  "lock-plus",
  "lock-question",
  "lock-search",
  "lock-share",
  "lock-square-rounded",
  "lock-square",
  "lock-star",
  "lock-up",
  "lock-x",
  "lock",
  "logic-and",
  "logic-buffer",
  "logic-nand",
  "logic-nor",
  "logic-not",
  "logic-or",
  "logic-xnor",
  "logic-xor",
  "login-2",
  "login",
  "logout-2",
  "logout",
  "logs",
  "lollipop-off",
  "lollipop",
  "luggage-off",
  "luggage",
  "lungs-off",
  "lungs",
  "macro-off",
  "macro",
  "magnet-off",
  "magnet",
  "magnetic",
  "mail-ai",
  "mail-bitcoin",
  "mail-bolt",
  "mail-cancel",
  "mail-check",
  "mail-code",
  "mail-cog",
  "mail-dollar",
  "mail-down",
  "mail-exclamation",
  "mail-fast",
  "mail-forward",
  "mail-heart",
  "mail-minus",
  "mail-off",
  "mail-opened",
  "mail-pause",
  "mail-pin",
  "mail-plus",
  "mail-question",
  "mail-search",
  "mail-share",
  "mail-spark",
  "mail-star",
  "mail-up",
  "mail-x",
  "mail",
  "mailbox-off",
  "mailbox",
  "man",
  "manual-gearbox",
  "map-2",
  "map-bolt",
  "map-cancel",
  "map-check",
  "map-code",
  "map-cog",
  "map-discount",
  "map-dollar",
  "map-down",
  "map-east",
  "map-exclamation",
  "map-heart",
  "map-minus",
  "map-north",
  "map-off",
  "map-pause",
  "map-pin-2",
  "map-pin-bolt",
  "map-pin-cancel",
  "map-pin-check",
  "map-pin-code",
  "map-pin-cog",
  "map-pin-dollar",
  "map-pin-down",
  "map-pin-exclamation",
  "map-pin-heart",
  "map-pin-minus",
  "map-pin-off",
  "map-pin-pause",
  "map-pin-pin",
  "map-pin-plus",
  "map-pin-question",
  "map-pin-search",
  "map-pin-share",
  "map-pin-star",
  "map-pin-up",
  "map-pin-x",
  "map-pin",
  "map-pins",
  "map-plus",
  "map-question",
  "map-route",
  "map-search",
  "map-share",
  "map-south",
  "map-star",
  "map-up",
  "map-west",
  "map-x",
  "map",
  "markdown-off",
  "markdown",
  "marquee-2",
  "marquee-off",
  "marquee",
  "mars",
  "mask-off",
  "mask",
  "masks-theater-off",
  "masks-theater",
  "massage",
  "matchstick",
  "math-1-divide-2",
  "math-1-divide-3",
  "math-avg",
  "math-cos",
  "math-ctg",
  "math-equal-greater",
  "math-equal-lower",
  "math-function-off",
  "math-function-y",
  "math-function",
  "math-greater",
  "math-integral-x",
  "math-integral",
  "math-integrals",
  "math-lower",
  "math-max-min",
  "math-max",
  "math-min",
  "math-not",
  "math-off",
  "math-pi-divide-2",
  "math-pi",
  "math-sec",
  "math-sin",
  "math-symbols",
  "math-tg",
  "math-x-divide-2",
  "math-x-divide-y-2",
  "math-x-divide-y",
  "math-x-floor-divide-y",
  "math-x-minus-x",
  "math-x-minus-y",
  "math-x-plus-x",
  "math-x-plus-y",
  "math-xy",
  "math-y-minus-y",
  "math-y-plus-y",
  "math",
  "maximize-off",
  "maximize",
  "meat-off",
  "meat",
  "medal-2",
  "medal",
  "medical-cross-circle",
  "medical-cross-off",
  "medical-cross",
  "medicine-syrup",
  "meeple",
  "melon",
  "menorah",
  "menu-2",
  "menu-3",
  "menu-4",
  "menu-deep",
  "menu-order",
  "menu",
  "message-2-bolt",
  "message-2-cancel",
  "message-2-check",
  "message-2-code",
  "message-2-cog",
  "message-2-dollar",
  "message-2-down",
  "message-2-exclamation",
  "message-2-heart",
  "message-2-minus",
  "message-2-off",
  "message-2-pause",
  "message-2-pin",
  "message-2-plus",
  "message-2-question",
  "message-2-search",
  "message-2-share",
  "message-2-star",
  "message-2-up",
  "message-2-x",
  "message-2",
  "message-bolt",
  "message-cancel",
  "message-chatbot",
  "message-check",
  "message-circle-bolt",
  "message-circle-cancel",
  "message-circle-check",
  "message-circle-code",
  "message-circle-cog",
  "message-circle-dollar",
  "message-circle-down",
  "message-circle-exclamation",
  "message-circle-heart",
  "message-circle-minus",
  "message-circle-off",
  "message-circle-pause",
  "message-circle-pin",
  "message-circle-plus",
  "message-circle-question",
  "message-circle-search",
  "message-circle-share",
  "message-circle-star",
  "message-circle-up",
  "message-circle-user",
  "message-circle-x",
  "message-circle",
  "message-code",
  "message-cog",
  "message-dollar",
  "message-dots",
  "message-down",
  "message-exclamation",
  "message-forward",
  "message-heart",
  "message-language",
  "message-minus",
  "message-off",
  "message-pause",
  "message-pin",
  "message-plus",
  "message-question",
  "message-reply",
  "message-report",
  "message-search",
  "message-share",
  "message-star",
  "message-up",
  "message-user",
  "message-x",
  "message",
  "messages-off",
  "messages",
  "meteor-off",
  "meteor",
  "meter-cube",
  "meter-square",
  "metronome",
  "michelin-bib-gourmand",
  "michelin-star-green",
  "michelin-star",
  "mickey",
  "microphone-2-off",
  "microphone-2",
  "microphone-off",
  "microphone",
  "microscope-off",
  "microscope",
  "microwave-off",
  "microwave",
  "military-award",
  "military-rank",
  "milk-off",
  "milk",
  "milkshake",
  "minimize",
  "minus-vertical",
  "minus",
  "mist-off",
  "mist",
  "mobiledata-off",
  "mobiledata",
  "moneybag",
  "monkeybar",
  "mood-angry",
  "mood-annoyed-2",
  "mood-annoyed",
  "mood-bitcoin",
  "mood-boy",
  "mood-check",
  "mood-cog",
  "mood-confuzed",
  "mood-crazy-happy",
  "mood-cry",
  "mood-dollar",
  "mood-edit",
  "mood-empty",
  "mood-happy",
  "mood-heart",
  "mood-kid",
  "mood-look-down",
  "mood-look-left",
  "mood-look-right",
  "mood-look-up",
  "mood-minus",
  "mood-nerd",
  "mood-nervous",
  "mood-neutral",
  "mood-off",
  "mood-pin",
  "mood-plus",
  "mood-puzzled",
  "mood-sad-2",
  "mood-sad-dizzy",
  "mood-sad-squint",
  "mood-sad",
  "mood-search",
  "mood-share",
  "mood-sick",
  "mood-silence",
  "mood-sing",
  "mood-smile-beam",
  "mood-smile-dizzy",
  "mood-smile",
  "mood-spark",
  "mood-surprised",
  "mood-tongue-wink-2",
  "mood-tongue-wink",
  "mood-tongue",
  "mood-unamused",
  "mood-up",
  "mood-wink-2",
  "mood-wink",
  "mood-wrrr",
  "mood-x",
  "mood-xd",
  "moon-2",
  "moon-off",
  "moon-stars",
  "moon",
  "moped",
  "motorbike",
  "mountain-off",
  "mountain",
  "mouse-2",
  "mouse-off",
  "mouse",
  "moustache",
  "movie-off",
  "movie",
  "mug-off",
  "mug",
  "multiplier-0-5x",
  "multiplier-1-5x",
  "multiplier-1x",
  "multiplier-2x",
  "mushroom-off",
  "mushroom",
  "music-bolt",
  "music-cancel",
  "music-check",
  "music-code",
  "music-cog",
  "music-discount",
  "music-dollar",
  "music-down",
  "music-exclamation",
  "music-heart",
  "music-minus",
  "music-off",
  "music-pause",
  "music-pin",
  "music-plus",
  "music-question",
  "music-search",
  "music-share",
  "music-star",
  "music-up",
  "music-x",
  "music",
  "navigation-bolt",
  "navigation-cancel",
  "navigation-check",
  "navigation-code",
  "navigation-cog",
  "navigation-discount",
  "navigation-dollar",
  "navigation-down",
  "navigation-east",
  "navigation-exclamation",
  "navigation-heart",
  "navigation-minus",
  "navigation-north",
  "navigation-off",
  "navigation-pause",
  "navigation-pin",
  "navigation-plus",
  "navigation-question",
  "navigation-search",
  "navigation-share",
  "navigation-south",
  "navigation-star",
  "navigation-top",
  "navigation-up",
  "navigation-west",
  "navigation-x",
  "navigation",
  "needle-thread",
  "needle",
  "network-off",
  "network",
  "new-section",
  "news-off",
  "news",
  "nfc-off",
  "nfc",
  "no-copyright",
  "no-creative-commons",
  "no-derivatives",
  "north-star",
  "note-off",
  "note",
  "notebook-off",
  "notebook",
  "notes-off",
  "notes",
  "notification-off",
  "notification",
  "number-0-small",
  "number-0",
  "number-1-small",
  "number-1",
  "number-10-small",
  "number-10",
  "number-100-small",
  "number-11-small",
  "number-11",
  "number-12-small",
  "number-123",
  "number-13-small",
  "number-14-small",
  "number-15-small",
  "number-16-small",
  "number-17-small",
  "number-18-small",
  "number-19-small",
  "number-2-small",
  "number-2",
  "number-20-small",
  "number-21-small",
  "number-22-small",
  "number-23-small",
  "number-24-small",
  "number-25-small",
  "number-26-small",
  "number-27-small",
  "number-28-small",
  "number-29-small",
  "number-3-small",
  "number-3",
  "number-30-small",
  "number-31-small",
  "number-32-small",
  "number-33-small",
  "number-34-small",
  "number-35-small",
  "number-36-small",
  "number-37-small",
  "number-38-small",
  "number-39-small",
  "number-4-small",
  "number-4",
  "number-40-small",
  "number-41-small",
  "number-42-small",
  "number-43-small",
  "number-44-small",
  "number-45-small",
  "number-46-small",
  "number-47-small",
  "number-48-small",
  "number-49-small",
  "number-5-small",
  "number-5",
  "number-50-small",
  "number-51-small",
  "number-52-small",
  "number-53-small",
  "number-54-small",
  "number-55-small",
  "number-56-small",
  "number-57-small",
  "number-58-small",
  "number-59-small",
  "number-6-small",
  "number-6",
  "number-60-small",
  "number-61-small",
  "number-62-small",
  "number-63-small",
  "number-64-small",
  "number-65-small",
  "number-66-small",
  "number-67-small",
  "number-68-small",
  "number-69-small",
  "number-7-small",
  "number-7",
  "number-70-small",
  "number-71-small",
  "number-72-small",
  "number-73-small",
  "number-74-small",
  "number-75-small",
  "number-76-small",
  "number-77-small",
  "number-78-small",
  "number-79-small",
  "number-8-small",
  "number-8",
  "number-80-small",
  "number-81-small",
  "number-82-small",
  "number-83-small",
  "number-84-small",
  "number-85-small",
  "number-86-small",
  "number-87-small",
  "number-88-small",
  "number-89-small",
  "number-9-small",
  "number-9",
  "number-90-small",
  "number-91-small",
  "number-92-small",
  "number-93-small",
  "number-94-small",
  "number-95-small",
  "number-96-small",
  "number-97-small",
  "number-98-small",
  "number-99-small",
  "number",
  "numbers",
  "nurse",
  "nut",
  "object-scan",
  "octagon-minus-2",
  "octagon-minus",
  "octagon-off",
  "octagon-plus-2",
  "octagon-plus",
  "octagon",
  "octahedron-off",
  "octahedron-plus",
  "octahedron",
  "old",
  "olympics-off",
  "olympics",
  "om",
  "omega",
  "outbound",
  "outlet",
  "oval-vertical",
  "oval",
  "overline",
  "package-export",
  "package-import",
  "package-off",
  "package",
  "packages",
  "pacman",
  "page-break",
  "paint-off",
  "paint",
  "palette-off",
  "palette",
  "panorama-horizontal-off",
  "panorama-horizontal",
  "panorama-vertical-off",
  "panorama-vertical",
  "paper-bag-off",
  "paper-bag",
  "paperclip",
  "parachute-off",
  "parachute",
  "parentheses-off",
  "parentheses",
  "parking-circle",
  "parking-off",
  "parking",
  "password-fingerprint",
  "password-mobile-phone",
  "password-user",
  "password",
  "paw-off",
  "paw",
  "paywall",
  "pdf",
  "peace",
  "pencil-bolt",
  "pencil-cancel",
  "pencil-check",
  "pencil-code",
  "pencil-cog",
  "pencil-discount",
  "pencil-dollar",
  "pencil-down",
  "pencil-exclamation",
  "pencil-heart",
  "pencil-minus",
  "pencil-off",
  "pencil-pause",
  "pencil-pin",
  "pencil-plus",
  "pencil-question",
  "pencil-search",
  "pencil-share",
  "pencil-star",
  "pencil-up",
  "pencil-x",
  "pencil",
  "pennant-2",
  "pennant-off",
  "pennant",
  "pentagon-minus",
  "pentagon-number-0",
  "pentagon-number-1",
  "pentagon-number-2",
  "pentagon-number-3",
  "pentagon-number-4",
  "pentagon-number-5",
  "pentagon-number-6",
  "pentagon-number-7",
  "pentagon-number-8",
  "pentagon-number-9",
  "pentagon-off",
  "pentagon-plus",
  "pentagon-x",
  "pentagon",
  "pentagram",
  "pepper-off",
  "pepper",
  "percentage-0",
  "percentage-10",
  "percentage-100",
  "percentage-20",
  "percentage-25",
  "percentage-30",
  "percentage-33",
  "percentage-40",
  "percentage-50",
  "percentage-60",
  "percentage-66",
  "percentage-70",
  "percentage-75",
  "percentage-80",
  "percentage-90",
  "percentage",
  "perfume",
  "perspective-off",
  "perspective",
  "phone-call",
  "phone-calling",
  "phone-check",
  "phone-done",
  "phone-end",
  "phone-incoming",
  "phone-off",
  "phone-outgoing",
  "phone-pause",
  "phone-plus",
  "phone-ringing",
  "phone-spark",
  "phone-x",
  "phone",
  "photo-ai",
  "photo-bitcoin",
  "photo-bolt",
  "photo-cancel",
  "photo-check",
  "photo-circle-minus",
  "photo-circle-plus",
  "photo-circle",
  "photo-code",
  "photo-cog",
  "photo-dollar",
  "photo-down",
  "photo-edit",
  "photo-exclamation",
  "photo-heart",
  "photo-hexagon",
  "photo-minus",
  "photo-off",
  "photo-pause",
  "photo-pentagon",
  "photo-pin",
  "photo-plus",
  "photo-question",
  "photo-scan",
  "photo-search",
  "photo-sensor-2",
  "photo-sensor-3",
  "photo-sensor",
  "photo-share",
  "photo-shield",
  "photo-spark",
  "photo-square-rounded",
  "photo-star",
  "photo-up",
  "photo-video",
  "photo-x",
  "photo",
  "physotherapist",
  "piano",
  "pick",
  "picnic-table",
  "picture-in-picture-off",
  "picture-in-picture-on",
  "picture-in-picture-top",
  "picture-in-picture",
  "pig-money",
  "pig-off",
  "pig",
  "pilcrow-left",
  "pilcrow-right",
  "pilcrow",
  "pill-off",
  "pill",
  "pills",
  "pin-end",
  "pin-invoke",
  "pin",
  "ping-pong",
  "pinned-off",
  "pinned",
  "pizza-off",
  "pizza",
  "placeholder",
  "plane-arrival",
  "plane-departure",
  "plane-inflight",
  "plane-off",
  "plane-tilt",
  "plane",
  "planet-off",
  "planet",
  "plant-2-off",
  "plant-2",
  "plant-off",
  "plant",
  "play-basketball",
  "play-card-1",
  "play-card-10",
  "play-card-2",
  "play-card-3",
  "play-card-4",
  "play-card-5",
  "play-card-6",
  "play-card-7",
  "play-card-8",
  "play-card-9",
  "play-card-a",
  "play-card-j",
  "play-card-k",
  "play-card-off",
  "play-card-q",
  "play-card-star",
  "play-card",
  "play-football",
  "play-handball",
  "play-volleyball",
  "player-eject",
  "player-pause",
  "player-play",
  "player-record",
  "player-skip-back",
  "player-skip-forward",
  "player-stop",
  "player-track-next",
  "player-track-prev",
  "playlist-add",
  "playlist-off",
  "playlist-x",
  "playlist",
  "playstation-circle",
  "playstation-square",
  "playstation-triangle",
  "playstation-x",
  "plug-connected-x",
  "plug-connected",
  "plug-off",
  "plug-x",
  "plug",
  "plus-equal",
  "plus-minus",
  "plus",
  "png",
  "podium-off",
  "podium",
  "point-off",
  "point",
  "pointer-bolt",
  "pointer-cancel",
  "pointer-check",
  "pointer-code",
  "pointer-cog",
  "pointer-dollar",
  "pointer-down",
  "pointer-exclamation",
  "pointer-heart",
  "pointer-minus",
  "pointer-off",
  "pointer-pause",
  "pointer-pin",
  "pointer-plus",
  "pointer-question",
  "pointer-search",
  "pointer-share",
  "pointer-star",
  "pointer-up",
  "pointer-x",
  "pointer",
  "pokeball-off",
  "pokeball",
  "poker-chip",
  "polaroid",
  "polygon-off",
  "polygon",
  "poo",
  "pool-off",
  "pool",
  "power",
  "pray",
  "premium-rights",
  "prescription",
  "presentation-analytics",
  "presentation-off",
  "presentation",
  "printer-off",
  "printer",
  "prism-light",
  "prism-off",
  "prism-plus",
  "prism",
  "prison",
  "progress-alert",
  "progress-bolt",
  "progress-check",
  "progress-down",
  "progress-help",
  "progress-x",
  "progress",
  "prompt",
  "prong",
  "propeller-off",
  "propeller",
  "protocol",
  "pumpkin-scary",
  "puzzle-2",
  "puzzle-off",
  "puzzle",
  "pyramid-off",
  "pyramid-plus",
  "pyramid",
  "qrcode-off",
  "qrcode",
  "question-mark",
  "quote-off",
  "quote",
  "quotes",
  "radar-2",
  "radar-off",
  "radar",
  "radio-off",
  "radio",
  "radioactive-off",
  "radioactive",
  "radius-bottom-left",
  "radius-bottom-right",
  "radius-top-left",
  "radius-top-right",
  "rainbow-off",
  "rainbow",
  "rating-12-plus",
  "rating-14-plus",
  "rating-16-plus",
  "rating-18-plus",
  "rating-21-plus",
  "razor-electric",
  "razor",
  "receipt-2",
  "receipt-bitcoin",
  "receipt-dollar",
  "receipt-euro",
  "receipt-off",
  "receipt-pound",
  "receipt-refund",
  "receipt-rupee",
  "receipt-tax",
  "receipt-yen",
  "receipt-yuan",
  "receipt",
  "recharging",
  "record-mail-off",
  "record-mail",
  "rectangle-rounded-bottom",
  "rectangle-rounded-top",
  "rectangle-vertical",
  "rectangle",
  "rectangular-prism-off",
  "rectangular-prism-plus",
  "rectangular-prism",
  "recycle-off",
  "recycle",
  "refresh-alert",
  "refresh-dot",
  "refresh-off",
  "refresh",
  "regex-off",
  "regex",
  "registered",
  "relation-many-to-many",
  "relation-one-to-many",
  "relation-one-to-one",
  "reload",
  "reorder",
  "repeat-off",
  "repeat-once",
  "repeat",
  "replace-off",
  "replace",
  "report-analytics",
  "report-medical",
  "report-money",
  "report-off",
  "report-search",
  "report",
  "reserved-line",
  "resize",
  "restore",
  "rewind-backward-10",
  "rewind-backward-15",
  "rewind-backward-20",
  "rewind-backward-30",
  "rewind-backward-40",
  "rewind-backward-5",
  "rewind-backward-50",
  "rewind-backward-60",
  "rewind-forward-10",
  "rewind-forward-15",
  "rewind-forward-20",
  "rewind-forward-30",
  "rewind-forward-40",
  "rewind-forward-5",
  "rewind-forward-50",
  "rewind-forward-60",
  "ribbon-health",
  "rings",
  "ripple-off",
  "ripple",
  "road-off",
  "road-sign",
  "road",
  "robot-face",
  "robot-off",
  "robot",
  "rocket-off",
  "rocket",
  "roller-skating",
  "rollercoaster-off",
  "rollercoaster",
  "rosette-discount-check-off",
  "rosette-discount-check",
  "rosette-discount-off",
  "rosette-discount",
  "rosette-number-0",
  "rosette-number-1",
  "rosette-number-2",
  "rosette-number-3",
  "rosette-number-4",
  "rosette-number-5",
  "rosette-number-6",
  "rosette-number-7",
  "rosette-number-8",
  "rosette-number-9",
  "rosette",
  "rotate-2",
  "rotate-360",
  "rotate-3d",
  "rotate-clockwise-2",
  "rotate-clockwise",
  "rotate-dot",
  "rotate-rectangle",
  "rotate",
  "route-2",
  "route-alt-left",
  "route-alt-right",
  "route-off",
  "route-scan",
  "route-square-2",
  "route-square",
  "route-x-2",
  "route-x",
  "route",
  "router-off",
  "router",
  "row-insert-bottom",
  "row-insert-top",
  "row-remove",
  "rss",
  "rubber-stamp-off",
  "rubber-stamp",
  "ruler-2-off",
  "ruler-2",
  "ruler-3",
  "ruler-measure-2",
  "ruler-measure",
  "ruler-off",
  "ruler",
  "run",
  "rv-truck",
  "s-turn-down",
  "s-turn-left",
  "s-turn-right",
  "s-turn-up",
  "sailboat-2",
  "sailboat-off",
  "sailboat",
  "salad",
  "salt",
  "sandbox",
  "satellite-off",
  "satellite",
  "sausage",
  "scale-off",
  "scale-outline-off",
  "scale-outline",
  "scale",
  "scan-eye",
  "scan-position",
  "scan",
  "schema-off",
  "schema",
  "school-bell",
  "school-off",
  "school",
  "scissors-off",
  "scissors",
  "scooter-electric",
  "scooter",
  "scoreboard",
  "screen-share-off",
  "screen-share",
  "screenshot",
  "scribble-off",
  "scribble",
  "script-minus",
  "script-plus",
  "script-x",
  "script",
  "scuba-diving-tank",
  "scuba-diving",
  "scuba-mask-off",
  "scuba-mask",
  "sdk",
  "search-off",
  "search",
  "section-sign",
  "section",
  "seeding-off",
  "seeding",
  "select-all",
  "select",
  "selector",
  "send-2",
  "send-off",
  "send",
  "seo",
  "separator-horizontal",
  "separator-vertical",
  "separator",
  "server-2",
  "server-bolt",
  "server-cog",
  "server-off",
  "server-spark",
  "server",
  "servicemark",
  "settings-2",
  "settings-automation",
  "settings-bolt",
  "settings-cancel",
  "settings-check",
  "settings-code",
  "settings-cog",
  "settings-dollar",
  "settings-down",
  "settings-exclamation",
  "settings-heart",
  "settings-minus",
  "settings-off",
  "settings-pause",
  "settings-pin",
  "settings-plus",
  "settings-question",
  "settings-search",
  "settings-share",
  "settings-spark",
  "settings-star",
  "settings-up",
  "settings-x",
  "settings",
  "shadow-off",
  "shadow",
  "shape-2",
  "shape-3",
  "shape-off",
  "shape",
  "share-2",
  "share-3",
  "share-off",
  "share",
  "shareplay",
  "shield-bolt",
  "shield-cancel",
  "shield-check",
  "shield-checkered",
  "shield-chevron",
  "shield-code",
  "shield-cog",
  "shield-dollar",
  "shield-down",
  "shield-exclamation",
  "shield-half",
  "shield-heart",
  "shield-lock",
  "shield-minus",
  "shield-off",
  "shield-pause",
  "shield-pin",
  "shield-plus",
  "shield-question",
  "shield-search",
  "shield-share",
  "shield-star",
  "shield-up",
  "shield-x",
  "shield",
  "ship-off",
  "ship",
  "shirt-off",
  "shirt-sport",
  "shirt",
  "shoe-off",
  "shoe",
  "shopping-bag-check",
  "shopping-bag-discount",
  "shopping-bag-edit",
  "shopping-bag-exclamation",
  "shopping-bag-heart",
  "shopping-bag-minus",
  "shopping-bag-plus",
  "shopping-bag-search",
  "shopping-bag-x",
  "shopping-bag",
  "shopping-cart-bolt",
  "shopping-cart-cancel",
  "shopping-cart-check",
  "shopping-cart-code",
  "shopping-cart-cog",
  "shopping-cart-copy",
  "shopping-cart-discount",
  "shopping-cart-dollar",
  "shopping-cart-down",
  "shopping-cart-exclamation",
  "shopping-cart-heart",
  "shopping-cart-minus",
  "shopping-cart-off",
  "shopping-cart-pause",
  "shopping-cart-pin",
  "shopping-cart-plus",
  "shopping-cart-question",
  "shopping-cart-search",
  "shopping-cart-share",
  "shopping-cart-star",
  "shopping-cart-up",
  "shopping-cart-x",
  "shopping-cart",
  "shovel-pitchforks",
  "shovel",
  "shredder",
  "sign-left",
  "sign-right",
  "signal-2g",
  "signal-3g",
  "signal-4g-plus",
  "signal-4g",
  "signal-5g",
  "signal-6g",
  "signal-e",
  "signal-g",
  "signal-h-plus",
  "signal-h",
  "signal-lte",
  "signature-off",
  "signature",
  "sitemap-off",
  "sitemap",
  "skateboard-off",
  "skateboard",
  "skateboarding",
  "skew-x",
  "skew-y",
  "ski-jumping",
  "skull",
  "slash",
  "slashes",
  "sleigh",
  "slice",
  "slideshow",
  "smart-home-off",
  "smart-home",
  "smoking-no",
  "smoking",
  "snowboarding",
  "snowflake-off",
  "snowflake",
  "snowman",
  "soccer-field",
  "social-off",
  "social",
  "sock",
  "sofa-off",
  "sofa",
  "solar-electricity",
  "solar-panel-2",
  "solar-panel",
  "sort-0-9",
  "sort-9-0",
  "sort-a-z",
  "sort-ascending-2",
  "sort-ascending-letters",
  "sort-ascending-numbers",
  "sort-ascending-shapes",
  "sort-ascending-small-big",
  "sort-ascending",
  "sort-descending-2",
  "sort-descending-letters",
  "sort-descending-numbers",
  "sort-descending-shapes",
  "sort-descending-small-big",
  "sort-descending",
  "sort-z-a",
  "sos",
  "soup-off",
  "soup",
  "source-code",
  "space-off",
  "space",
  "spaces",
  "spacing-horizontal",
  "spacing-vertical",
  "spade",
  "sparkles",
  "speakerphone",
  "speedboat",
  "sphere-off",
  "sphere-plus",
  "sphere",
  "spider",
  "spiral-off",
  "spiral",
  "sport-billard",
  "spray",
  "spy-off",
  "spy",
  "sql",
  "square-arrow-down",
  "square-arrow-left",
  "square-arrow-right",
  "square-arrow-up",
  "square-asterisk",
  "square-check",
  "square-chevron-down",
  "square-chevron-left",
  "square-chevron-right",
  "square-chevron-up",
  "square-chevrons-down",
  "square-chevrons-left",
  "square-chevrons-right",
  "square-chevrons-up",
  "square-dot",
  "square-f0",
  "square-f1",
  "square-f2",
  "square-f3",
  "square-f4",
  "square-f5",
  "square-f6",
  "square-f7",
  "square-f8",
  "square-f9",
  "square-forbid-2",
  "square-forbid",
  "square-half",
  "square-key",
  "square-letter-a",
  "square-letter-b",
  "square-letter-c",
  "square-letter-d",
  "square-letter-e",
  "square-letter-f",
  "square-letter-g",
  "square-letter-h",
  "square-letter-i",
  "square-letter-j",
  "square-letter-k",
  "square-letter-l",
  "square-letter-m",
  "square-letter-n",
  "square-letter-o",
  "square-letter-p",
  "square-letter-q",
  "square-letter-r",
  "square-letter-s",
  "square-letter-t",
  "square-letter-u",
  "square-letter-v",
  "square-letter-w",
  "square-letter-x",
  "square-letter-y",
  "square-letter-z",
  "square-minus",
  "square-number-0",
  "square-number-1",
  "square-number-2",
  "square-number-3",
  "square-number-4",
  "square-number-5",
  "square-number-6",
  "square-number-7",
  "square-number-8",
  "square-number-9",
  "square-off",
  "square-percentage",
  "square-plus-2",
  "square-plus",
  "square-root-2",
  "square-root",
  "square-rotated-forbid-2",
  "square-rotated-forbid",
  "square-rotated-off",
  "square-rotated",
  "square-rounded-arrow-down",
  "square-rounded-arrow-left",
  "square-rounded-arrow-right",
  "square-rounded-arrow-up",
  "square-rounded-check",
  "square-rounded-chevron-down",
  "square-rounded-chevron-left",
  "square-rounded-chevron-right",
  "square-rounded-chevron-up",
  "square-rounded-chevrons-down",
  "square-rounded-chevrons-left",
  "square-rounded-chevrons-right",
  "square-rounded-chevrons-up",
  "square-rounded-letter-a",
  "square-rounded-letter-b",
  "square-rounded-letter-c",
  "square-rounded-letter-d",
  "square-rounded-letter-e",
  "square-rounded-letter-f",
  "square-rounded-letter-g",
  "square-rounded-letter-h",
  "square-rounded-letter-i",
  "square-rounded-letter-j",
  "square-rounded-letter-k",
  "square-rounded-letter-l",
  "square-rounded-letter-m",
  "square-rounded-letter-n",
  "square-rounded-letter-o",
  "square-rounded-letter-p",
  "square-rounded-letter-q",
  "square-rounded-letter-r",
  "square-rounded-letter-s",
  "square-rounded-letter-t",
  "square-rounded-letter-u",
  "square-rounded-letter-v",
  "square-rounded-letter-w",
  "square-rounded-letter-x",
  "square-rounded-letter-y",
  "square-rounded-letter-z",
  "square-rounded-minus-2",
  "square-rounded-minus",
  "square-rounded-number-0",
  "square-rounded-number-1",
  "square-rounded-number-2",
  "square-rounded-number-3",
  "square-rounded-number-4",
  "square-rounded-number-5",
  "square-rounded-number-6",
  "square-rounded-number-7",
  "square-rounded-number-8",
  "square-rounded-number-9",
  "square-rounded-percentage",
  "square-rounded-plus-2",
  "square-rounded-plus",
  "square-rounded-x",
  "square-rounded",
  "square-toggle-horizontal",
  "square-toggle",
  "square-x",
  "square",
  "squares-diagonal",
  "squares-selected",
  "squares",
  "stack-2",
  "stack-3",
  "stack-back",
  "stack-backward",
  "stack-forward",
  "stack-front",
  "stack-middle",
  "stack-pop",
  "stack-push",
  "stack",
  "stairs-down",
  "stairs-up",
  "stairs",
  "star-half",
  "star-off",
  "star",
  "stars-off",
  "stars",
  "status-change",
  "steam",
  "steering-wheel-off",
  "steering-wheel",
  "step-into",
  "step-out",
  "stereo-glasses",
  "stethoscope-off",
  "stethoscope",
  "sticker-2",
  "sticker",
  "stopwatch",
  "storm-off",
  "storm",
  "stretching-2",
  "stretching",
  "strikethrough",
  "submarine",
  "subscript",
  "subtask",
  "sum-off",
  "sum",
  "sun-electricity",
  "sun-high",
  "sun-low",
  "sun-moon",
  "sun-off",
  "sun-wind",
  "sun",
  "sunglasses",
  "sunrise",
  "sunset-2",
  "sunset",
  "superscript",
  "svg",
  "swimming",
  "swipe-down",
  "swipe-left",
  "swipe-right",
  "swipe-up",
  "swipe",
  "switch-2",
  "switch-3",
  "switch-horizontal",
  "switch-vertical",
  "switch",
  "sword-off",
  "sword",
  "swords",
  "table-alias",
  "table-column",
  "table-down",
  "table-export",
  "table-heart",
  "table-import",
  "table-minus",
  "table-off",
  "table-options",
  "table-plus",
  "table-row",
  "table-share",
  "table-shortcut",
  "table-spark",
  "table",
  "tag-off",
  "tag-starred",
  "tag",
  "tags-off",
  "tags",
  "tallymark-1",
  "tallymark-2",
  "tallymark-3",
  "tallymark-4",
  "tallymarks",
  "tank",
  "target-arrow",
  "target-off",
  "target",
  "tax-euro",
  "tax-pound",
  "tax",
  "teapot",
  "telescope-off",
  "telescope",
  "temperature-celsius",
  "temperature-fahrenheit",
  "temperature-minus",
  "temperature-off",
  "temperature-plus",
  "temperature-snow",
  "temperature-sun",
  "temperature",
  "template-off",
  "template",
  "tent-off",
  "tent",
  "terminal-2",
  "terminal",
  "test-pipe-2",
  "test-pipe-off",
  "test-pipe",
  "tex",
  "text-caption",
  "text-color",
  "text-decrease",
  "text-direction-ltr",
  "text-direction-rtl",
  "text-grammar",
  "text-increase",
  "text-orientation",
  "text-plus",
  "text-recognition",
  "text-resize",
  "text-scan-2",
  "text-size",
  "text-spellcheck",
  "text-wrap-column",
  "text-wrap-disabled",
  "text-wrap",
  "texture",
  "theater",
  "thermometer",
  "thumb-down-off",
  "thumb-down",
  "thumb-up-off",
  "thumb-up",
  "tic-tac",
  "ticket-off",
  "ticket",
  "tie",
  "tilde",
  "tilt-shift-off",
  "tilt-shift",
  "time-duration-0",
  "time-duration-10",
  "time-duration-15",
  "time-duration-30",
  "time-duration-45",
  "time-duration-5",
  "time-duration-60",
  "time-duration-90",
  "time-duration-off",
  "timeline-event-exclamation",
  "timeline-event-minus",
  "timeline-event-plus",
  "timeline-event-text",
  "timeline-event-x",
  "timeline-event",
  "timeline",
  "timezone",
  "tip-jar-euro",
  "tip-jar-pound",
  "tip-jar",
  "tir",
  "toggle-left",
  "toggle-right",
  "toilet-paper-off",
  "toilet-paper",
  "toml",
  "tool",
  "tools-kitchen-2-off",
  "tools-kitchen-2",
  "tools-kitchen-3",
  "tools-kitchen-off",
  "tools-kitchen",
  "tools-off",
  "tools",
  "tooltip",
  "topology-bus",
  "topology-complex",
  "topology-full-hierarchy",
  "topology-full",
  "topology-ring-2",
  "topology-ring-3",
  "topology-ring",
  "topology-star-2",
  "topology-star-3",
  "topology-star-ring-2",
  "topology-star-ring-3",
  "topology-star-ring",
  "topology-star",
  "torii",
  "tornado",
  "tournament",
  "tower-off",
  "tower",
  "track",
  "tractor",
  "trademark",
  "traffic-cone-off",
  "traffic-cone",
  "traffic-lights-off",
  "traffic-lights",
  "train",
  "transaction-bitcoin",
  "transaction-dollar",
  "transaction-euro",
  "transaction-pound",
  "transaction-rupee",
  "transaction-yen",
  "transaction-yuan",
  "transfer-in",
  "transfer-out",
  "transfer-vertical",
  "transfer",
  "transform-point-bottom-left",
  "transform-point-bottom-right",
  "transform-point-top-left",
  "transform-point-top-right",
  "transform-point",
  "transform",
  "transition-bottom",
  "transition-left",
  "transition-right",
  "transition-top",
  "trash-off",
  "trash-x",
  "trash",
  "treadmill",
  "tree",
  "trees",
  "trekking",
  "trending-down-2",
  "trending-down-3",
  "trending-down",
  "trending-up-2",
  "trending-up-3",
  "trending-up",
  "triangle-inverted",
  "triangle-minus-2",
  "triangle-minus",
  "triangle-off",
  "triangle-plus-2",
  "triangle-plus",
  "triangle-square-circle",
  "triangle",
  "triangles",
  "trident",
  "trolley",
  "trophy-off",
  "trophy",
  "trowel",
  "truck-delivery",
  "truck-loading",
  "truck-off",
  "truck-return",
  "truck",
  "txt",
  "typeface",
  "typography-off",
  "typography",
  "u-turn-left",
  "u-turn-right",
  "ufo-off",
  "ufo",
  "umbrella-2",
  "umbrella-closed-2",
  "umbrella-closed",
  "umbrella-off",
  "umbrella",
  "underline",
  "universe",
  "unlink",
  "upload",
  "urgent",
  "usb",
  "user-bitcoin",
  "user-bolt",
  "user-cancel",
  "user-check",
  "user-circle",
  "user-code",
  "user-cog",
  "user-dollar",
  "user-down",
  "user-edit",
  "user-exclamation",
  "user-heart",
  "user-hexagon",
  "user-minus",
  "user-off",
  "user-pause",
  "user-pentagon",
  "user-pin",
  "user-plus",
  "user-question",
  "user-scan",
  "user-screen",
  "user-search",
  "user-share",
  "user-shield",
  "user-square-rounded",
  "user-square",
  "user-star",
  "user-up",
  "user-x",
  "user",
  "users-group",
  "users-minus",
  "users-plus",
  "users",
  "uv-index",
  "ux-circle",
  "vaccine-bottle-off",
  "vaccine-bottle",
  "vaccine-off",
  "vaccine",
  "vacuum-cleaner",
  "variable-minus",
  "variable-off",
  "variable-plus",
  "variable",
  "vector-bezier-2",
  "vector-bezier-arc",
  "vector-bezier-circle",
  "vector-bezier",
  "vector-off",
  "vector-spline",
  "vector-triangle-off",
  "vector-triangle",
  "vector",
  "venus",
  "versions-off",
  "versions",
  "video-minus",
  "video-off",
  "video-plus",
  "video",
  "view-360-arrow",
  "view-360-number",
  "view-360-off",
  "view-360",
  "viewfinder-off",
  "viewfinder",
  "viewport-narrow",
  "viewport-short",
  "viewport-tall",
  "viewport-wide",
  "vinyl",
  "vip-off",
  "vip",
  "virus-off",
  "virus-search",
  "virus",
  "vocabulary-off",
  "vocabulary",
  "volcano",
  "volume-2",
  "volume-3",
  "volume-off",
  "volume",
  "vs",
  "walk",
  "wall-off",
  "wall",
  "wallet-off",
  "wallet",
  "wallpaper-off",
  "wallpaper",
  "wand-off",
  "wand",
  "wash-dry-1",
  "wash-dry-2",
  "wash-dry-3",
  "wash-dry-a",
  "wash-dry-dip",
  "wash-dry-f",
  "wash-dry-flat",
  "wash-dry-hang",
  "wash-dry-off",
  "wash-dry-p",
  "wash-dry-shade",
  "wash-dry-w",
  "wash-dry",
  "wash-dryclean-off",
  "wash-dryclean",
  "wash-eco",
  "wash-gentle",
  "wash-hand",
  "wash-machine",
  "wash-off",
  "wash-press",
  "wash-temperature-1",
  "wash-temperature-2",
  "wash-temperature-3",
  "wash-temperature-4",
  "wash-temperature-5",
  "wash-temperature-6",
  "wash-tumble-dry",
  "wash-tumble-off",
  "wash",
  "waterpolo",
  "wave-saw-tool",
  "wave-sine",
  "wave-square",
  "waves-electricity",
  "webhook-off",
  "webhook",
  "weight",
  "wheel",
  "wheelchair-off",
  "wheelchair",
  "whirl",
  "wifi-0",
  "wifi-1",
  "wifi-2",
  "wifi-off",
  "wifi",
  "wind-electricity",
  "wind-off",
  "wind",
  "windmill-off",
  "windmill",
  "window-maximize",
  "window-minimize",
  "window-off",
  "window",
  "windsock",
  "wiper-wash",
  "wiper",
  "woman",
  "wood",
  "world-bolt",
  "world-cancel",
  "world-check",
  "world-code",
  "world-cog",
  "world-dollar",
  "world-down",
  "world-download",
  "world-exclamation",
  "world-heart",
  "world-latitude",
  "world-longitude",
  "world-minus",
  "world-off",
  "world-pause",
  "world-pin",
  "world-plus",
  "world-question",
  "world-search",
  "world-share",
  "world-star",
  "world-up",
  "world-upload",
  "world-www",
  "world-x",
  "world",
  "wrecking-ball",
  "writing-off",
  "writing-sign-off",
  "writing-sign",
  "writing",
  "x-power-y",
  "x",
  "xbox-a",
  "xbox-b",
  "xbox-x",
  "xbox-y",
  "xd",
  "xxx",
  "yin-yang",
  "yoga",
  "zeppelin-off",
  "zeppelin",
  "zip",
  "zodiac-aquarius",
  "zodiac-aries",
  "zodiac-cancer",
  "zodiac-capricorn",
  "zodiac-gemini",
  "zodiac-leo",
  "zodiac-libra",
  "zodiac-pisces",
  "zodiac-sagittarius",
  "zodiac-scorpio",
  "zodiac-taurus",
  "zodiac-virgo",
  "zoom-cancel",
  "zoom-check",
  "zoom-code",
  "zoom-exclamation",
  "zoom-in-area",
  "zoom-in",
  "zoom-money",
  "zoom-out-area",
  "zoom-out",
  "zoom-pan",
  "zoom-question",
  "zoom-replace",
  "zoom-reset",
  "zoom-scan",
  "zoom",
  "zzz-off",
  "zzz",
  "accessible-filled",
  "ad-circle-filled",
  "ad-filled",
  "adjustments-filled",
  "affiliate-filled",
  "alarm-minus-filled",
  "alarm-plus-filled",
  "alarm-snooze-filled",
  "alarm-filled",
  "alert-circle-filled",
  "alert-hexagon-filled",
  "alert-octagon-filled",
  "alert-square-rounded-filled",
  "alert-square-filled",
  "alert-triangle-filled",
  "alien-filled",
  "align-box-bottom-center-filled",
  "align-box-bottom-left-filled",
  "align-box-bottom-right-filled",
  "align-box-center-middle-filled",
  "align-box-left-bottom-filled",
  "align-box-left-middle-filled",
  "align-box-left-top-filled",
  "align-box-right-bottom-filled",
  "align-box-right-middle-filled",
  "align-box-right-top-filled",
  "align-box-top-center-filled",
  "align-box-top-left-filled",
  "align-box-top-right-filled",
  "analyze-filled",
  "app-window-filled",
  "apple-filled",
  "apps-filled",
  "archive-filled",
  "arrow-autofit-content-filled",
  "arrow-badge-down-filled",
  "arrow-badge-left-filled",
  "arrow-badge-right-filled",
  "arrow-badge-up-filled",
  "arrow-big-down-line-filled",
  "arrow-big-down-lines-filled",
  "arrow-big-down-filled",
  "arrow-big-left-line-filled",
  "arrow-big-left-lines-filled",
  "arrow-big-left-filled",
  "arrow-big-right-line-filled",
  "arrow-big-right-lines-filled",
  "arrow-big-right-filled",
  "arrow-big-up-line-filled",
  "arrow-big-up-lines-filled",
  "arrow-big-up-filled",
  "arrow-down-circle-filled",
  "arrow-down-rhombus-filled",
  "arrow-down-square-filled",
  "arrow-guide-filled",
  "arrow-left-circle-filled",
  "arrow-left-rhombus-filled",
  "arrow-left-square-filled",
  "arrow-move-down-filled",
  "arrow-move-left-filled",
  "arrow-move-right-filled",
  "arrow-move-up-filled",
  "arrow-right-circle-filled",
  "arrow-right-rhombus-filled",
  "arrow-right-square-filled",
  "arrow-up-circle-filled",
  "arrow-up-rhombus-filled",
  "arrow-up-square-filled",
  "artboard-filled",
  "article-filled",
  "aspect-ratio-filled",
  "assembly-filled",
  "asset-filled",
  "atom-2-filled",
  "automatic-gearbox-filled",
  "award-filled",
  "baby-carriage-filled",
  "backspace-filled",
  "badge-3d-filled",
  "badge-4k-filled",
  "badge-8k-filled",
  "badge-ad-filled",
  "badge-ar-filled",
  "badge-cc-filled",
  "badge-hd-filled",
  "badge-sd-filled",
  "badge-tm-filled",
  "badge-vo-filled",
  "badge-vr-filled",
  "badge-wc-filled",
  "badge-filled",
  "badges-filled",
  "balloon-filled",
  "ballpen-filled",
  "bandage-filled",
  "barbell-filled",
  "barrier-block-filled",
  "basket-filled",
  "bath-filled",
  "battery-1-filled",
  "battery-2-filled",
  "battery-3-filled",
  "battery-4-filled",
  "battery-automotive-filled",
  "battery-vertical-1-filled",
  "battery-vertical-2-filled",
  "battery-vertical-3-filled",
  "battery-vertical-4-filled",
  "battery-vertical-filled",
  "battery-filled",
  "bed-flat-filled",
  "bed-filled",
  "beer-filled",
  "bell-minus-filled",
  "bell-plus-filled",
  "bell-ringing-2-filled",
  "bell-ringing-filled",
  "bell-x-filled",
  "bell-z-filled",
  "bell-filled",
  "bike-filled",
  "binary-tree-2-filled",
  "binary-tree-filled",
  "binoculars-filled",
  "biohazard-filled",
  "blade-filled",
  "blender-filled",
  "blob-filled",
  "bolt-filled",
  "bomb-filled",
  "bone-filled",
  "bong-filled",
  "book-filled",
  "bookmark-filled",
  "bookmarks-filled",
  "boom-filled",
  "bottle-filled",
  "bounce-left-filled",
  "bounce-right-filled",
  "bow-filled",
  "bowl-chopsticks-filled",
  "bowl-spoon-filled",
  "bowl-filled",
  "box-align-bottom-left-filled",
  "box-align-bottom-right-filled",
  "box-align-bottom-filled",
  "box-align-left-filled",
  "box-align-right-filled",
  "box-align-top-left-filled",
  "box-align-top-right-filled",
  "box-align-top-filled",
  "box-multiple-filled",
  "brand-apple-filled",
  "brand-discord-filled",
  "brand-dribbble-filled",
  "brand-facebook-filled",
  "brand-github-filled",
  "brand-google-filled",
  "brand-patreon-filled",
  "brand-paypal-filled",
  "brand-spotify-filled",
  "brand-tiktok-filled",
  "brand-twitter-filled",
  "brand-x-filled",
  "brand-youtube-filled",
  "bread-filled",
  "briefcase-2-filled",
  "briefcase-filled",
  "brightness-auto-filled",
  "brightness-down-filled",
  "brightness-up-filled",
  "brightness-filled",
  "bubble-filled",
  "bug-filled",
  "building-broadcast-tower-filled",
  "bulb-filled",
  "butterfly-filled",
  "cactus-filled",
  "calculator-filled",
  "calendar-filled",
  "camera-filled",
  "campfire-filled",
  "candle-filled",
  "cannabis-filled",
  "capsule-horizontal-filled",
  "capsule-filled",
  "capture-filled",
  "car-4wd-filled",
  "car-fan-filled",
  "car-suv-filled",
  "car-filled",
  "carambola-filled",
  "cardboards-filled",
  "cards-filled",
  "caret-down-filled",
  "caret-left-right-filled",
  "caret-left-filled",
  "caret-right-filled",
  "caret-up-down-filled",
  "caret-up-filled",
  "carousel-horizontal-filled",
  "carousel-vertical-filled",
  "cash-banknote-filled",
  "category-filled",
  "charging-pile-filled",
  "chart-area-line-filled",
  "chart-area-filled",
  "chart-bubble-filled",
  "chart-candle-filled",
  "chart-donut-filled",
  "chart-dots-filled",
  "chart-grid-dots-filled",
  "chart-pie-filled",
  "cherry-filled",
  "chess-bishop-filled",
  "chess-king-filled",
  "chess-knight-filled",
  "chess-queen-filled",
  "chess-rook-filled",
  "chess-filled",
  "christmas-tree-filled",
  "circle-arrow-down-left-filled",
  "circle-arrow-down-right-filled",
  "circle-arrow-down-filled",
  "circle-arrow-left-filled",
  "circle-arrow-right-filled",
  "circle-arrow-up-left-filled",
  "circle-arrow-up-right-filled",
  "circle-arrow-up-filled",
  "circle-check-filled",
  "circle-dot-filled",
  "circle-key-filled",
  "circle-letter-a-filled",
  "circle-letter-b-filled",
  "circle-letter-c-filled",
  "circle-letter-d-filled",
  "circle-letter-e-filled",
  "circle-letter-f-filled",
  "circle-letter-g-filled",
  "circle-letter-h-filled",
  "circle-letter-i-filled",
  "circle-letter-j-filled",
  "circle-letter-k-filled",
  "circle-letter-l-filled",
  "circle-letter-m-filled",
  "circle-letter-n-filled",
  "circle-letter-o-filled",
  "circle-letter-p-filled",
  "circle-letter-q-filled",
  "circle-letter-r-filled",
  "circle-letter-s-filled",
  "circle-letter-t-filled",
  "circle-letter-u-filled",
  "circle-letter-v-filled",
  "circle-letter-w-filled",
  "circle-letter-x-filled",
  "circle-letter-y-filled",
  "circle-letter-z-filled",
  "circle-number-0-filled",
  "circle-number-1-filled",
  "circle-number-2-filled",
  "circle-number-3-filled",
  "circle-number-4-filled",
  "circle-number-5-filled",
  "circle-number-6-filled",
  "circle-number-7-filled",
  "circle-number-8-filled",
  "circle-number-9-filled",
  "circle-percentage-filled",
  "circle-plus-filled",
  "circle-rectangle-filled",
  "circle-x-filled",
  "circle-filled",
  "circles-filled",
  "clock-hour-1-filled",
  "clock-hour-10-filled",
  "clock-hour-11-filled",
  "clock-hour-12-filled",
  "clock-hour-2-filled",
  "clock-hour-3-filled",
  "clock-hour-4-filled",
  "clock-hour-5-filled",
  "clock-hour-6-filled",
  "clock-hour-7-filled",
  "clock-hour-8-filled",
  "clock-hour-9-filled",
  "clock-filled",
  "cloud-filled",
  "clover-filled",
  "clubs-filled",
  "code-circle-2-filled",
  "code-circle-filled",
  "coin-bitcoin-filled",
  "coin-euro-filled",
  "coin-monero-filled",
  "coin-pound-filled",
  "coin-rupee-filled",
  "coin-taka-filled",
  "coin-yen-filled",
  "coin-yuan-filled",
  "coin-filled",
  "compass-filled",
  "cone-2-filled",
  "cone-filled",
  "contrast-2-filled",
  "contrast-filled",
  "cookie-man-filled",
  "cookie-filled",
  "copy-check-filled",
  "copy-minus-filled",
  "copy-plus-filled",
  "copy-x-filled",
  "copyleft-filled",
  "copyright-filled",
  "credit-card-filled",
  "crop-1-1-filled",
  "crop-16-9-filled",
  "crop-3-2-filled",
  "crop-5-4-filled",
  "crop-7-5-filled",
  "crop-landscape-filled",
  "crop-portrait-filled",
  "cross-filled",
  "dashboard-filled",
  "device-cctv-filled",
  "device-desktop-filled",
  "device-gamepad-3-filled",
  "device-heart-monitor-filled",
  "device-imac-filled",
  "device-ipad-filled",
  "device-mobile-filled",
  "device-remote-filled",
  "device-speaker-filled",
  "device-tablet-filled",
  "device-tv-old-filled",
  "device-tv-filled",
  "device-unknown-filled",
  "device-usb-filled",
  "device-vision-pro-filled",
  "device-watch-filled",
  "dialpad-filled",
  "diamond-filled",
  "diamonds-filled",
  "dice-1-filled",
  "dice-2-filled",
  "dice-3-filled",
  "dice-4-filled",
  "dice-5-filled",
  "dice-6-filled",
  "dice-filled",
  "direction-sign-filled",
  "directions-filled",
  "disc-filled",
  "discount-filled",
  "droplet-half-2-filled",
  "droplet-half-filled",
  "droplet-filled",
  "egg-cracked-filled",
  "egg-filled",
  "elevator-filled",
  "exclamation-circle-filled",
  "eye-filled",
  "favicon-filled",
  "feather-filled",
  "file-x-filled",
  "file-filled",
  "filter-filled",
  "flag-2-filled",
  "flag-3-filled",
  "flag-filled",
  "flask-2-filled",
  "flask-filled",
  "flower-filled",
  "folder-filled",
  "forbid-2-filled",
  "forbid-filled",
  "fountain-filled",
  "function-filled",
  "gauge-filled",
  "ghost-2-filled",
  "ghost-filled",
  "gift-card-filled",
  "gift-filled",
  "glass-full-filled",
  "glass-filled",
  "globe-filled",
  "gps-filled",
  "graph-filled",
  "guitar-pick-filled",
  "hanger-2-filled",
  "headphones-filled",
  "heart-filled",
  "help-circle-filled",
  "help-hexagon-filled",
  "help-octagon-filled",
  "help-square-rounded-filled",
  "help-square-filled",
  "help-triangle-filled",
  "hexagon-letter-a-filled",
  "hexagon-letter-b-filled",
  "hexagon-letter-c-filled",
  "hexagon-letter-d-filled",
  "hexagon-letter-e-filled",
  "hexagon-letter-f-filled",
  "hexagon-letter-g-filled",
  "hexagon-letter-h-filled",
  "hexagon-letter-i-filled",
  "hexagon-letter-j-filled",
  "hexagon-letter-k-filled",
  "hexagon-letter-l-filled",
  "hexagon-letter-m-filled",
  "hexagon-letter-n-filled",
  "hexagon-letter-o-filled",
  "hexagon-letter-p-filled",
  "hexagon-letter-q-filled",
  "hexagon-letter-r-filled",
  "hexagon-letter-s-filled",
  "hexagon-letter-t-filled",
  "hexagon-letter-u-filled",
  "hexagon-letter-v-filled",
  "hexagon-letter-w-filled",
  "hexagon-letter-x-filled",
  "hexagon-letter-y-filled",
  "hexagon-letter-z-filled",
  "hexagon-minus-filled",
  "hexagon-number-0-filled",
  "hexagon-number-1-filled",
  "hexagon-number-2-filled",
  "hexagon-number-3-filled",
  "hexagon-number-4-filled",
  "hexagon-number-5-filled",
  "hexagon-number-6-filled",
  "hexagon-number-7-filled",
  "hexagon-number-8-filled",
  "hexagon-number-9-filled",
  "hexagon-plus-filled",
  "hexagon-filled",
  "home-filled",
  "hospital-circle-filled",
  "hourglass-filled",
  "icons-filled",
  "info-circle-filled",
  "info-hexagon-filled",
  "info-octagon-filled",
  "info-square-rounded-filled",
  "info-square-filled",
  "info-triangle-filled",
  "inner-shadow-bottom-left-filled",
  "inner-shadow-bottom-right-filled",
  "inner-shadow-bottom-filled",
  "inner-shadow-left-filled",
  "inner-shadow-right-filled",
  "inner-shadow-top-left-filled",
  "inner-shadow-top-right-filled",
  "inner-shadow-top-filled",
  "ironing-1-filled",
  "ironing-2-filled",
  "ironing-3-filled",
  "ironing-steam-filled",
  "ironing-filled",
  "jetpack-filled",
  "jewish-star-filled",
  "key-filled",
  "keyframe-align-center-filled",
  "keyframe-align-horizontal-filled",
  "keyframe-align-vertical-filled",
  "keyframe-filled",
  "keyframes-filled",
  "label-important-filled",
  "label-filled",
  "lasso-polygon-filled",
  "layout-2-filled",
  "layout-align-bottom-filled",
  "layout-align-center-filled",
  "layout-align-left-filled",
  "layout-align-middle-filled",
  "layout-align-right-filled",
  "layout-align-top-filled",
  "layout-bottombar-collapse-filled",
  "layout-bottombar-expand-filled",
  "layout-bottombar-filled",
  "layout-cards-filled",
  "layout-dashboard-filled",
  "layout-distribute-horizontal-filled",
  "layout-distribute-vertical-filled",
  "layout-grid-filled",
  "layout-kanban-filled",
  "layout-list-filled",
  "layout-navbar-collapse-filled",
  "layout-navbar-expand-filled",
  "layout-navbar-filled",
  "layout-sidebar-left-collapse-filled",
  "layout-sidebar-left-expand-filled",
  "layout-sidebar-right-collapse-filled",
  "layout-sidebar-right-expand-filled",
  "layout-sidebar-right-filled",
  "layout-sidebar-filled",
  "layout-filled",
  "lego-filled",
  "live-photo-filled",
  "location-filled",
  "lock-square-rounded-filled",
  "lock-filled",
  "lungs-filled",
  "macro-filled",
  "magnet-filled",
  "mail-opened-filled",
  "mail-filled",
  "man-filled",
  "manual-gearbox-filled",
  "map-pin-filled",
  "medical-cross-filled",
  "melon-filled",
  "message-chatbot-filled",
  "message-circle-filled",
  "message-report-filled",
  "message-filled",
  "meteor-filled",
  "michelin-star-filled",
  "mickey-filled",
  "microphone-filled",
  "microwave-filled",
  "military-rank-filled",
  "milk-filled",
  "mood-angry-filled",
  "mood-confuzed-filled",
  "mood-crazy-happy-filled",
  "mood-empty-filled",
  "mood-happy-filled",
  "mood-kid-filled",
  "mood-neutral-filled",
  "mood-sad-filled",
  "mood-smile-filled",
  "mood-wrrr-filled",
  "moon-filled",
  "mountain-filled",
  "mouse-filled",
  "mug-filled",
  "mushroom-filled",
  "navigation-filled",
  "octagon-filled",
  "oval-vertical-filled",
  "oval-filled",
  "paint-filled",
  "panorama-horizontal-filled",
  "panorama-vertical-filled",
  "parking-circle-filled",
  "paw-filled",
  "pennant-2-filled",
  "pennant-filled",
  "pentagon-filled",
  "phone-filled",
  "photo-filled",
  "picture-in-picture-top-filled",
  "picture-in-picture-filled",
  "pill-filled",
  "pin-filled",
  "pinned-filled",
  "pizza-filled",
  "play-card-1-filled",
  "play-card-10-filled",
  "play-card-2-filled",
  "play-card-3-filled",
  "play-card-4-filled",
  "play-card-5-filled",
  "play-card-6-filled",
  "play-card-7-filled",
  "play-card-8-filled",
  "play-card-9-filled",
  "play-card-a-filled",
  "play-card-j-filled",
  "play-card-k-filled",
  "play-card-q-filled",
  "play-card-star-filled",
  "player-eject-filled",
  "player-pause-filled",
  "player-play-filled",
  "player-record-filled",
  "player-skip-back-filled",
  "player-skip-forward-filled",
  "player-stop-filled",
  "player-track-next-filled",
  "player-track-prev-filled",
  "point-filled",
  "pointer-filled",
  "polaroid-filled",
  "poo-filled",
  "presentation-analytics-filled",
  "presentation-filled",
  "puzzle-filled",
  "radar-filled",
  "radioactive-filled",
  "receipt-filled",
  "rectangle-vertical-filled",
  "rectangle-filled",
  "relation-many-to-many-filled",
  "relation-one-to-many-filled",
  "relation-one-to-one-filled",
  "replace-filled",
  "rosette-discount-check-filled",
  "rosette-discount-filled",
  "rosette-filled",
  "salad-filled",
  "scuba-diving-tank-filled",
  "section-filled",
  "seeding-filled",
  "settings-filled",
  "shield-check-filled",
  "shield-checkered-filled",
  "shield-half-filled",
  "shield-lock-filled",
  "shield-filled",
  "shirt-filled",
  "shopping-cart-filled",
  "sign-left-filled",
  "sign-right-filled",
  "sitemap-filled",
  "sort-ascending-2-filled",
  "sort-ascending-shapes-filled",
  "sort-descending-2-filled",
  "sort-descending-shapes-filled",
  "soup-filled",
  "spade-filled",
  "square-arrow-down-filled",
  "square-arrow-left-filled",
  "square-arrow-right-filled",
  "square-arrow-up-filled",
  "square-asterisk-filled",
  "square-check-filled",
  "square-chevron-down-filled",
  "square-chevron-left-filled",
  "square-chevron-right-filled",
  "square-chevron-up-filled",
  "square-chevrons-down-filled",
  "square-chevrons-left-filled",
  "square-chevrons-right-filled",
  "square-chevrons-up-filled",
  "square-dot-filled",
  "square-f0-filled",
  "square-f1-filled",
  "square-f2-filled",
  "square-f3-filled",
  "square-f4-filled",
  "square-f5-filled",
  "square-f6-filled",
  "square-f7-filled",
  "square-f8-filled",
  "square-f9-filled",
  "square-letter-a-filled",
  "square-letter-b-filled",
  "square-letter-c-filled",
  "square-letter-d-filled",
  "square-letter-e-filled",
  "square-letter-f-filled",
  "square-letter-g-filled",
  "square-letter-h-filled",
  "square-letter-i-filled",
  "square-letter-j-filled",
  "square-letter-k-filled",
  "square-letter-l-filled",
  "square-letter-m-filled",
  "square-letter-n-filled",
  "square-letter-o-filled",
  "square-letter-p-filled",
  "square-letter-q-filled",
  "square-letter-r-filled",
  "square-letter-s-filled",
  "square-letter-t-filled",
  "square-letter-u-filled",
  "square-letter-v-filled",
  "square-letter-w-filled",
  "square-letter-x-filled",
  "square-letter-y-filled",
  "square-letter-z-filled",
  "square-minus-filled",
  "square-number-0-filled",
  "square-number-1-filled",
  "square-number-2-filled",
  "square-number-3-filled",
  "square-number-4-filled",
  "square-number-5-filled",
  "square-number-6-filled",
  "square-number-7-filled",
  "square-number-8-filled",
  "square-number-9-filled",
  "square-rotated-filled",
  "square-rounded-arrow-down-filled",
  "square-rounded-arrow-left-filled",
  "square-rounded-arrow-right-filled",
  "square-rounded-arrow-up-filled",
  "square-rounded-check-filled",
  "square-rounded-chevron-down-filled",
  "square-rounded-chevron-left-filled",
  "square-rounded-chevron-right-filled",
  "square-rounded-chevron-up-filled",
  "square-rounded-chevrons-down-filled",
  "square-rounded-chevrons-left-filled",
  "square-rounded-chevrons-right-filled",
  "square-rounded-chevrons-up-filled",
  "square-rounded-letter-a-filled",
  "square-rounded-letter-b-filled",
  "square-rounded-letter-c-filled",
  "square-rounded-letter-d-filled",
  "square-rounded-letter-e-filled",
  "square-rounded-letter-f-filled",
  "square-rounded-letter-g-filled",
  "square-rounded-letter-h-filled",
  "square-rounded-letter-i-filled",
  "square-rounded-letter-j-filled",
  "square-rounded-letter-k-filled",
  "square-rounded-letter-l-filled",
  "square-rounded-letter-m-filled",
  "square-rounded-letter-n-filled",
  "square-rounded-letter-o-filled",
  "square-rounded-letter-p-filled",
  "square-rounded-letter-q-filled",
  "square-rounded-letter-r-filled",
  "square-rounded-letter-s-filled",
  "square-rounded-letter-t-filled",
  "square-rounded-letter-u-filled",
  "square-rounded-letter-v-filled",
  "square-rounded-letter-w-filled",
  "square-rounded-letter-x-filled",
  "square-rounded-letter-y-filled",
  "square-rounded-letter-z-filled",
  "square-rounded-minus-filled",
  "square-rounded-number-0-filled",
  "square-rounded-number-1-filled",
  "square-rounded-number-2-filled",
  "square-rounded-number-3-filled",
  "square-rounded-number-4-filled",
  "square-rounded-number-5-filled",
  "square-rounded-number-6-filled",
  "square-rounded-number-7-filled",
  "square-rounded-number-8-filled",
  "square-rounded-number-9-filled",
  "square-rounded-plus-filled",
  "square-rounded-x-filled",
  "square-rounded-filled",
  "square-x-filled",
  "square-filled",
  "squares-filled",
  "stack-2-filled",
  "stack-3-filled",
  "stack-filled",
  "star-half-filled",
  "star-filled",
  "stars-filled",
  "steering-wheel-filled",
  "sun-filled",
  "sunglasses-filled",
  "swipe-down-filled",
  "swipe-left-filled",
  "swipe-right-filled",
  "swipe-up-filled",
  "table-filled",
  "tag-filled",
  "tags-filled",
  "test-pipe-2-filled",
  "thumb-down-filled",
  "thumb-up-filled",
  "tilt-shift-filled",
  "timeline-event-filled",
  "toggle-left-filled",
  "toggle-right-filled",
  "transform-filled",
  "transition-bottom-filled",
  "transition-left-filled",
  "transition-right-filled",
  "transition-top-filled",
  "trash-x-filled",
  "trash-filled",
  "triangle-inverted-filled",
  "triangle-square-circle-filled",
  "triangle-filled",
  "trophy-filled",
  "umbrella-filled",
  "user-filled",
  "versions-filled",
  "windmill-filled",
  "woman-filled",
  "xbox-a-filled",
  "xbox-b-filled",
  "xbox-x-filled",
  "xbox-y-filled",
  "yin-yang-filled",
  "zeppelin-filled",
  "zoom-cancel-filled",
  "zoom-check-filled",
  "zoom-code-filled",
  "zoom-exclamation-filled",
  "zoom-in-area-filled",
  "zoom-in-filled",
  "zoom-money-filled",
  "zoom-out-area-filled",
  "zoom-out-filled",
  "zoom-pan-filled",
  "zoom-question-filled",
  "zoom-scan-filled",
  "zoom-filled"
];